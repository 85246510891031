import React, { ReactElement } from 'react';

interface Props {
    children: React.ReactChild;
    className?: string;
    id?: string;
    href: string;
    title?: string;
    onClick?: () => void;
    blankPage?: boolean;
    useDownload?: boolean;
    forwardRef?: React.MutableRefObject<any>;
}

const A = ({
    children,
    className,
    onClick,
    href,
    title,
    id,
    blankPage,
    useDownload,
    forwardRef,
}: Props): ReactElement => (
    <a
        ref={forwardRef}
        href={href ? '' + href : href}
        className={className}
        onClick={(e) => {
            onClick && onClick();
            href && !blankPage && window.pageRefresh && window.pageRefresh(e);
        }}
        id={id}
        title={title}
        target={blankPage ? '_blank' : undefined}
        download={useDownload}
    >
        {children}
    </a>
);

export default A;
