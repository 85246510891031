import Axios from 'axios';
import { getDiscipline } from './discipline';
import { getResponse } from 'utils/helpers/browser/getResponse';

export enum MethodEnum {
    GET = 'GET',
    POST = 'POST',
    PATCH = 'PATCH',
    PUT = 'PUT',
    DELETE = 'DELETE',
}

export default async function fetcher<ResponseType>(
    url: string,
    method?: MethodEnum,
    params?: Record<string, any>,
    data?: Record<string, any>,
    timeout?: number,
    headers?: any,
    allDataWithMessage?: boolean
): Promise<ResponseType> {
    return fetcherInner(
        url,
        method,
        params,
        data,
        timeout,
        false,
        headers,
        undefined,
        allDataWithMessage
    );
}

export async function fetcherFull<ResponseType>(
    url: string,
    method?: MethodEnum,
    params?: Record<string, any>,
    data?: Record<string, any>,
    timeout?: number,
    headers?: any,
    enableToastError?: boolean,
    allDataWithMessage?: boolean
): Promise<ResponseType> {
    return fetcherInner(
        url,
        method,
        params,
        data,
        timeout,
        true,
        headers,
        enableToastError,
        allDataWithMessage
    );
}

async function fetcherInner<ResponseType>(
    url: string,
    method: MethodEnum = MethodEnum.GET,
    params: Record<string, any> = {},
    data: Record<string, any> = null,
    timeout = 30000,
    full: boolean,
    headers?: any,
    enableToastError?: boolean,
    allDataWithMessage?: boolean
): Promise<ResponseType> {
    try {
        return getResponse<ResponseType>(
            await Axios.request({
                url,
                method,
                data,
                timeout,
                params: { discipline: getDiscipline(), ...params },
                headers,
            }),
            full,
            enableToastError,
            allDataWithMessage
        );
    } catch (error: any) {
        return getResponse<ResponseType>(
            error.response,
            full,
            enableToastError
        );
    }
}
