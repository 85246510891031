import React, { CSSProperties, MouseEvent, ReactElement } from 'react';
import classNames from 'classnames';
import A from 'components/layouts/A';
import styles from './Icon.module.scss';

interface Props {
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    style?: CSSProperties;
    className?: string;
    noStrip?: boolean;
    blankPage?: boolean;
    href?: string;
    id?: string;
    alt?: string;
    onClick?: (e: MouseEvent<SVGSVGElement>) => void;
}

const Icon = ({
    icon: Icon,
    className,
    style,
    noStrip,
    onClick,
    href,
    id,
    alt,
    blankPage,
}: Props): ReactElement => {
    if (!Icon) {
        return null;
    }

    const getIcon = (className?: string): ReactElement => (
        <Icon
            style={style}
            className={classNames(
                styles.root,
                { [styles.strip]: !noStrip, [styles.onHover]: !!onClick },
                className
            )}
            onClick={onClick}
        />
    );
    if (href) {
        return (
            <A
                href={href}
                className={classNames(styles.iconOnHover, className)}
                id={id}
                title={alt}
                blankPage={blankPage}
            >
                {getIcon()}
            </A>
        );
    }
    return getIcon(className);
};

export default Icon;
