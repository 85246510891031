import React, { VFC, useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import AngleDown from 'components/icon/icons/angle_down.svg';
import Icon from 'components/icon/Icon';
import { MAX_INTEGER_VALUE, MIN_INTEGER_VALUE } from 'constants/general';
import styles from './Input.module.scss';

interface Props {
    name?: string;
    className?: string;
    inputClassName?: string;
    bigger?: boolean;
    errorMessage?: string;
    placeholder?: string;
    max?: number;
    min?: number;
    value?: number | '';
    onChange: (value: number | '') => void;
    onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onMouseOver?: (e: React.MouseEvent<HTMLInputElement>) => void;
    onDragEnter?: (e: React.DragEvent<HTMLInputElement>) => void;
    onDragLeave?: (e: React.DragEvent<HTMLInputElement>) => void;
    onDrop?: (e: React.DragEvent<HTMLInputElement>) => void;
    onArrowClick?: (e: React.MouseEvent) => void;
    whiteBackground?: boolean;
    withBorder?: boolean;
    disabled?: boolean;
    hideArrows?: boolean;
    autoFocus?: boolean;
    attributes?: { [key: string]: string };
    step?: number;
    isDecimalNumber?: boolean;
    readOnly?: boolean;
    snapToMin?: boolean;
    snapToMax?: boolean;
}

const NumberInput: VFC<Props> = ({
    name,
    className,
    bigger,
    errorMessage,
    placeholder,
    max = MAX_INTEGER_VALUE,
    min = 0,
    isDecimalNumber,
    value,
    onKeyPress,
    onKeyDown,
    onChange,
    onBlur,
    onFocus,
    onMouseOver,
    onDragEnter,
    onDragLeave,
    onDrop,
    onArrowClick,
    whiteBackground,
    withBorder,
    attributes,
    disabled,
    hideArrows,
    autoFocus,
    step,
    readOnly,
    inputClassName,
    snapToMin = false,
    snapToMax = false,
}) => {
    const rewriteLocalValueRef = useRef(true);
    const [localValue, setLocalValue] = useState(
        value === '' || (!value && value !== 0) ? '' : value.toString()
    );

    const [selection, setSelection] = useState({ start: 0, end: 0 });
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const stringValue =
            value === '' || (!value && value !== 0) ? '' : value.toString();
        rewriteLocalValueRef.current && setLocalValue(stringValue);
        rewriteLocalValueRef.current = true;
    }, [value]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.setSelectionRange(selection.start, selection.end);
        }
    }, [inputRef, selection]);

    const handleNumberUp = (e: React.MouseEvent) => {
        if (disabled) return;

        let val = min || 1;

        if (value || value === 0) {
            const t_value = Math.floor(value);
            const t_step = step ? step : 1;
            val = t_value + t_step;

            if (min != undefined && val < min) {
                val = min;
            } else if (max != undefined && val > max) {
                val = value;
            }
        }

        onChange(val);
        setLocalValue(val.toString());
        onArrowClick?.(e);
    };

    const handleNumberDown = (e: React.MouseEvent) => {
        if (disabled) return;

        let val = min || -1;

        if (value || value === 0) {
            const t_value = Math.ceil(value);
            const t_step = step ? step : 1;
            val = t_value - t_step;

            if (min != undefined && val < min) {
                val = value;
            } else if (max != undefined && val > max) {
                val = max;
            }
        }

        onChange(val);
        setLocalValue(val.toString());
        onArrowClick?.(e);
    };

    const _onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (!isDecimalNumber && ['.', ','].includes(e.key)) {
            e.preventDefault();
            e.stopPropagation();
        }
        rewriteLocalValueRef.current = false;
        onKeyDown?.(e);
    };

    const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value.trim();
        const regex = /^$|(^[+-]?[0-9]{0,}[,.]?[0-9]{0,}$)/;
        let selectionShift = 0;

        if (val.match(regex)) {
            const numVal = val !== '' ? Number(val.replace(',', '.')) : '';
            if (
                ['', '+', '-', ',', '.'].includes(val) ||
                (numVal >= MIN_INTEGER_VALUE && numVal <= MAX_INTEGER_VALUE)
            ) {
                setLocalValue(val);
                onChange(
                    (val === '' || isNaN(numVal as number) ? '' : numVal) as
                        | number
                        | ''
                );
            } else {
                selectionShift = 1;
            }
        } else {
            selectionShift = 1;
        }

        setSelection({
            start: e.target.selectionStart - selectionShift,
            end: e.target.selectionStart - selectionShift,
        });
    };

    const _onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value.replace(',', '.');
        const numVal = Number(val);
        let newVal = val === '' || isNaN(numVal) ? '' : numVal;

        if (snapToMin && newVal !== '' && newVal < min) {
            newVal = min;
        } else if (snapToMax && max && newVal !== '' && newVal > max) {
            newVal = max;
        }

        !(value === '' && newVal === '') && onChange(newVal as number);
        setLocalValue(newVal !== '' ? newVal.toString() : newVal);
        onBlur?.(e);
    };

    return (
        <>
            <div className={classNames(styles.root, className)}>
                <input
                    name={name}
                    className={classNames(
                        styles.input,
                        styles.inputWithIcon,
                        inputClassName,
                        {
                            [styles.bigger]: bigger,
                            [styles.whiteBackground]: whiteBackground,
                            [styles.withBorder]: !!withBorder,
                        }
                    )}
                    onKeyPress={onKeyPress}
                    placeholder={placeholder}
                    type="text"
                    onChange={_onChange}
                    onBlur={_onBlur}
                    onKeyDown={_onKeyDown}
                    onFocus={onFocus}
                    onMouseOver={onMouseOver}
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                    onDrop={onDrop}
                    value={localValue}
                    disabled={disabled}
                    min={min}
                    max={max}
                    step={'any'}
                    autoFocus={autoFocus}
                    ref={inputRef}
                    readOnly={readOnly}
                    {...attributes}
                />
                {!hideArrows && (
                    <span
                        className={classNames(styles.inputIcon, {
                            [styles.biggerInputNumberIcons]: !!bigger,
                        })}
                    >
                        <div onClick={handleNumberUp}>
                            <Icon icon={AngleDown} />
                        </div>
                        <div onClick={handleNumberDown}>
                            <Icon icon={AngleDown} />
                        </div>
                    </span>
                )}
            </div>
            {!!errorMessage && (
                <div className={styles.error}>{errorMessage}</div>
            )}
        </>
    );
};

export default NumberInput;
