import React, { ReactElement } from 'react';
import classNames from 'classnames';
import styles from './H1.module.scss';

interface Props {
    children?: React.ReactChild;
    className?: string;
    mainAction?: React.ReactElement;
}

const H1 = ({ children, className, mainAction }: Props): ReactElement => (
    <h1
        className={classNames(styles.root, className, {
            [styles.flex]: !!mainAction,
        })}
    >
        {children}
        {mainAction}
    </h1>
);

export default H1;
