import { TFunction } from 'next-i18next';
import getConfig from 'next/config';

export const prettifyUrlString = (string: string): string => {
    return string
        ? string
              .replace(/https:\/\//g, '')
              .replace(/http:\/\//g, '')
              .replace(/\/$/g, '')
              .replace(/\/ /g, ' ')
              .replace(/\/,/g, ',')
        : null;
};

export const makeUrlFromString = (string: string): string => {
    if (
        string &&
        !string.startsWith('http://') &&
        !string.startsWith('https://')
    ) {
        return `https://${string}`;
    }

    return string;
};

let debounceTimer: NodeJS.Timer = null;

export const debounce = (
    time: number,
    fn: (...params: any[]) => any,
    immediately = false
) => {
    return (...args: any[]): void => {
        if (!!debounceTimer && immediately) {
            fn(args);
        }
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => fn(args), time);
    };
};

export const formatCurrencyString = (
    value: string | number | null | undefined,
    currency: string
): string => {
    return value || value === 0 ? `${value.toLocaleString()} ${currency}` : '';
};

export const formatPcsString = (
    t: TFunction,
    value: string | number
): string => {
    return `${value + ''} ${t('general.pcs')}`;
};

export const formatPercentageString = (value: string | number): string => {
    return `${value} %`;
};

export const roundToTwo = (num: number): number =>
    +(Math.round(Number(num + 'e+2')) + 'e-2');

export const getEnvVal = (): Record<string, any> =>
    getConfig()?.publicRuntimeConfig || {};

export const isSystemDowngraded = (): boolean =>
    getEnvVal().NEXT_PUBLIC_DOWNGRADE === 'true' ? true : false;

export const getTopBannerInfo = (): string => getEnvVal().NEXT_PUBLIC_TOP_INFO;

export const getSentryDSN = (): string => getEnvVal().NEXT_PUBLIC_SENTRY_DSN;
