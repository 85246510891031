import React, { MouseEvent, ReactElement } from 'react';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import CardIconInput from './CardIconInput';
import Icon from 'components/icon/Icon';
import { useTranslation } from 'utils/localization';
import Edit from 'components/icon/icons/edit.svg';
import Plus from 'components/icon/icons/plus_circle.svg';
import Export from 'components/icon/icons/export.svg';
import Printer from 'components/icon/icons/printer.svg';
import More from 'components/icon/icons/more.svg';
import ContextMenu from 'components/contextMenu/ContextMenu';
import A from 'components/layouts/A';
import Loading from 'components/loading/Loading';
import { isSystemDowngraded } from 'utils/helpers/helpers';
import { ExportTableProps } from 'utils/helpers/exportTable';
import styles from './CardIcon.module.scss';

const getCardIconTypes = (
    t: TFunction,
    icon: React.FC<React.SVGProps<SVGSVGElement>>,
    label: string,
    labelKey: string
) => ({
    edit: {
        icon: icon ? icon : Edit,
        label: label || t(labelKey ? labelKey : 'actions.edit'),
    },
    add: {
        icon: icon ? icon : Plus,
        label: label || t(labelKey ? labelKey : 'actions.add'),
    },
    export: {
        icon: icon ? icon : Export,
        label: label || t(labelKey ? labelKey : 'actions.export'),
    },
    export_table: {
        icon: icon ? icon : Export,
        label: label || t(labelKey ? labelKey : 'actions.export'),
    },
    print: {
        icon: icon ? icon : Printer,
        label: label || t(labelKey ? labelKey : 'actions.print'),
    },
    more: {
        icon: More,
        label: label || t(labelKey),
    },
    other: {
        icon: icon,
        label: label || t(labelKey),
    },
});

export interface InputActionInterface {
    confirmLabelKey: string;
    onClick: (value: string | number) => void;
    initValue?: string | number;
}

export interface ContextMenuOptionInterface {
    label: string;
    action: () => void;
}

export interface CardIconBaseInterface {
    onClick?: (e: MouseEvent<HTMLElement>) => void;
    inputActions?: InputActionInterface;
    justIcon?: boolean;
    isWhite?: boolean;
    icon?: React.FC<React.SVGProps<SVGSVGElement>>;
    labelKey?: string;
    label?: string;
    link?: string;
    blankPage?: boolean;
    iconClassName?: string;
    contextMenuActions?: ContextMenuOptionInterface[];
    exportTableData?: ExportTableProps;
    isIconCircle?: boolean;
}

export interface CardIconType extends CardIconBaseInterface {
    type:
        | 'edit'
        | 'print'
        | 'export'
        | 'export_table'
        | 'add'
        | 'more'
        | 'pipe-line'
        | 'number-input'
        | 'text-input'
        | 'other'
        | 'loading';
}

interface Props {
    className?: string;
    action: CardIconType;
}

const CardIcon = ({
    className,
    action: {
        type,
        onClick,
        justIcon,
        isWhite,
        link,
        blankPage,
        icon,
        label,
        labelKey,
        iconClassName,
        isIconCircle,
        contextMenuActions,
        inputActions,
    },
}: Props): ReactElement => {
    const { t } = useTranslation();

    const sysDowngrade = isSystemDowngraded();

    if (type === 'loading') {
        return (
            <>
                <span
                    className={classNames(styles.label, {
                        [styles.whiteColor]: !!isWhite && !sysDowngrade,
                    })}
                >
                    <Loading noMarginBottom />
                </span>
            </>
        );
    }
    if (type === 'pipe-line') {
        return (
            <div className={classNames(className, styles.pipeLine)}>
                <span />
            </div>
        );
    } else if (type === 'text-input' || type === 'number-input') {
        return (
            <CardIconInput
                type={type.split('-')[0] as 'text' | 'number'}
                actions={inputActions}
            />
        );
    }

    const data = getCardIconTypes(t, icon, label, labelKey)[
        `${type}` as
            | 'edit'
            | 'print'
            | 'export'
            | 'export_table'
            | 'add'
            | 'more'
            | 'other'
    ];
    const element = (
        <>
            {data.icon && (
                <Icon
                    icon={data.icon}
                    className={classNames(
                        styles.icon,
                        {
                            [styles.marginRight]: !justIcon,
                            [styles.whiteStroke]: !!isWhite && !sysDowngrade,
                            [styles.more]: type === 'more',
                            [styles.iconCircle]: isIconCircle,
                        },
                        iconClassName
                    )}
                />
            )}
            {!justIcon && (
                <span
                    className={classNames(styles.label, {
                        [styles.whiteColor]: !!isWhite && !sysDowngrade,
                    })}
                >
                    {data.label}
                </span>
            )}
        </>
    );

    if (type === 'more' || contextMenuActions) {
        return (
            <div
                className={classNames(
                    styles.cardIconRoot,
                    className,
                    styles.clickable
                )}
            >
                <ContextMenu
                    options={contextMenuActions.map((action) => ({
                        text: t(action.label),
                        onClick: action.action,
                    }))}
                >
                    {element}
                </ContextMenu>
            </div>
        );
    }

    if (link) {
        return (
            <A
                className={classNames(
                    styles.cardIconRoot,
                    className,
                    styles.clickable,
                    {
                        [styles.downgraded]: sysDowngrade,
                    }
                )}
                href={link}
                blankPage={blankPage}
            >
                {element}
            </A>
        );
    }

    return (
        <div
            className={classNames(
                styles.cardIconRoot,
                styles.bigger,
                className,
                {
                    [styles.clickable]: !!onClick,
                    [styles.downgraded]: sysDowngrade,
                }
            )}
            onClick={onClick}
        >
            {element}
        </div>
    );
};

export default CardIcon;
