import classNames from 'classnames';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { getTopBannerInfo } from 'utils/helpers/helpers';
import styles from './TopBanner.module.scss';

interface Props {
    isExportPage: boolean;
}

const TopBanner = ({ isExportPage }: Props): ReactElement => {
    const [show, setShow] = useState<boolean>(true);
    const content = useMemo<string>(() => getTopBannerInfo(), []);
    useEffect(() => setShow(!isExportPage), [isExportPage]);

    return (
        <div
            id="top-banner"
            className={classNames(styles.topBanner, {
                [styles.show]: !!content && show,
            })}
        >
            {content}
        </div>
    );
};

export default TopBanner;
