import React, { ReactElement, useEffect, useState } from 'react';
import { NextRouter, useRouter } from 'next/router';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'utils/localization';
import Icon from 'components/icon/Icon';
import InfoIcon from 'components/icon/icons/info.svg';
import SuccessIcon from 'components/icon/icons/check_circle.svg';
import WarningIcon from 'components/icon/icons/alert.svg';
import DangerIcon from 'components/icon/icons/alert_circle.svg';
import CancelIcon from 'components/icon/icons/cancel.svg';
import { createUrlParam } from 'constants/routes';
import { StateInterface } from 'components/interfaces/GeneralInterface';
import styles from './Toast.module.scss';

export enum MessageType {
    INFO = 'info',
    DANGER = 'danger',
    WARNING = 'warning',
    SUCCESS = 'success',
    DEFAULT = 'default',
}

export const MessageKeys = {
    MESSAGE: 'message',
    TITLE: 'message_title',
    TYPE: 'message_type',
    TRANSLATED: 'translated',
    MESSAGE_PARAMS: 'message_params',
    TITLE_PARAMS: 'title_params',
};

const pageRedirect = (url: string) => window.pageRedirect(url);

export const reloadWithMessage = (
    message: ToastInterface,
    delay?: number
): void => {
    const char = window.location.href.indexOf('?') === -1 ? '?' : '&';
    const params = getUrlParamMessage(message);
    const url = window.location.href + char + params;
    if (delay) {
        setTimeout(() => pageRedirect(url), delay);
    } else pageRedirect(url);
};

export const redirectWithMessage = (
    url: string,
    message: ToastInterface,
    newWindow?: boolean
): void => {
    const char = url.indexOf('?') === -1 ? '?' : '&';
    const params = getUrlParamMessage(message);
    window.pageRedirect(url + char + params, newWindow);
};

export const getUrlParamMessage = (message: ToastInterface): string => {
    let result = createUrlParam(MessageKeys.TYPE, message.messageType);
    if (message.message) {
        result += '&' + createUrlParam(MessageKeys.MESSAGE, message.message);
    }
    if (message.messageTitle) {
        result += '&' + createUrlParam(MessageKeys.TITLE, message.messageTitle);
    }
    if (message.translated) {
        result += '&' + createUrlParam(MessageKeys.TRANSLATED, 'true');
    }
    if (message.messageParams) {
        result +=
            '&' +
            createUrlParam(
                MessageKeys.MESSAGE_PARAMS,
                JSON.stringify(message.messageParams)
            );
    }
    if (message.titleParams) {
        result +=
            '&' +
            createUrlParam(
                MessageKeys.TITLE_PARAMS,
                JSON.stringify(message.titleParams)
            );
    }

    return result;
};

const getIcon = (
    messageType: MessageType
): React.FC<React.SVGProps<SVGSVGElement>> => {
    switch (messageType) {
        case MessageType.INFO:
            return InfoIcon;
        case MessageType.SUCCESS:
            return SuccessIcon;
        case MessageType.WARNING:
            return WarningIcon;
        case MessageType.DANGER:
            return DangerIcon;
    }
};

const setCharAt = (str: string, idx: number, newChr: string): string => {
    return str.substring(0, idx) + newChr + str.substring(idx + 1);
};

const replaceResult = (result: string, key: string): string => {
    const keyStr = '&' + key + '=';
    const keyStr2 = '?' + key + '=';
    const index = result.indexOf(keyStr);
    const index2 = result.indexOf(keyStr2);

    const getResult = (index: number, result: string, replaceKey: string) => {
        const start = result.substr(0, index);
        let rest = result.substr(index).replace(replaceKey, '');

        if (rest.indexOf('&') !== -1) {
            const temp = rest.substr(rest.indexOf('&'));

            if (rest.startsWith('?')) {
                rest = '?' + temp;
            } else {
                rest = temp;
            }
        } else {
            rest = '';
        }

        return start + rest;
    };

    if (index !== -1) {
        return getResult(index, result, keyStr);
    } else if (index2 !== -1) {
        return getResult(index2, result, keyStr2.substr(1));
    }

    return result;
};

const deleteMessageFromRoute = () => {
    let result = window.location.search;
    result = replaceResult(result, MessageKeys.MESSAGE);
    result = replaceResult(result, MessageKeys.TYPE);
    result = replaceResult(result, MessageKeys.TITLE);
    result = replaceResult(result, MessageKeys.TRANSLATED);
    result = replaceResult(result, MessageKeys.MESSAGE_PARAMS);
    result = replaceResult(result, MessageKeys.TITLE_PARAMS);

    if (result.charAt(1) === '&') {
        result = setCharAt(result, 1, '');
    }

    window.history.replaceState(
        window.history.state,
        '',
        `${window.location.pathname}${result}`
    );
};

const getMessageType = (type: string): MessageType => {
    if (type?.toLocaleUpperCase() in MessageType) {
        return type as MessageType;
    }

    return MessageType.DEFAULT;
};

interface Props {
    dismissTime?: number;
}

let pushMessageInner: React.Dispatch<React.SetStateAction<ToastInterface>>;

export const pushMessage = (
    message: string,
    messageType: MessageType,
    messageTitle?: string,
    translated?: boolean
): boolean => {
    if (pushMessageInner) {
        pushMessageInner({
            message,
            messageTitle,
            messageType,
            translated,
        });
        return true;
    }

    return false;
};

export interface ToastInterface {
    message?: string;
    messageTitle?: string;
    messageType: MessageType;
    translated?: boolean;
    messageParams?: StateInterface;
    titleParams?: StateInterface;
}

// Move to utils?
const getStringFromQuery = (Router: NextRouter, key: string): string => {
    const value = Router.query[`${key}`];
    const valueType = typeof value;
    if (valueType === 'string') {
        return value as string;
    } else if (
        valueType === 'object' &&
        value !== null &&
        value instanceof Array
    ) {
        return value[value.length - 1];
    }

    return '';
};

const getToastData = (Router: NextRouter): ToastInterface => {
    const toastData: ToastInterface = {
        message: getStringFromQuery(Router, MessageKeys.MESSAGE),
        messageTitle: getStringFromQuery(Router, MessageKeys.TITLE),
        messageType: getStringFromQuery(
            Router,
            MessageKeys.TYPE
        ) as MessageType,
        translated:
            !!Router.query[MessageKeys.TRANSLATED] &&
            Router.query[MessageKeys.TRANSLATED] !== 'false',
        messageParams:
            Router.query.message_params &&
            JSON.parse(Router.query[MessageKeys.MESSAGE_PARAMS] as string),
        titleParams:
            Router.query.title_params &&
            JSON.parse(Router.query[MessageKeys.TITLE_PARAMS] as string),
    };

    if (
        (toastData.message || toastData.messageTitle) &&
        toastData.messageType
    ) {
        return toastData;
    }

    return null;
};

const Toast = ({ dismissTime = 10000 }: Props): ReactElement => {
    const { t } = useTranslation();
    const Router = useRouter();
    const [toastData, setToastData] = useState<ToastInterface>(
        getToastData(Router)
    );

    pushMessageInner = setToastData;

    useEffect(() => {
        if (toastData && dismissTime) {
            deleteMessageFromRoute();
            const interval = setInterval(() => {
                deleteToast();
            }, dismissTime);

            return () => {
                clearInterval(interval);
            };
        }
    }, [toastData]);

    const deleteToast = () => {
        setToastData(null);
    };

    const getToastTranslation = (
        key: string,
        params: StateInterface
    ): string => {
        if (
            key.replace('_message', '').replace('_title', '') ===
            'The given data was invalid.'
        ) {
            return t('serverErrors.givenDataWasInvalid');
        } else if (key.indexOf(' ') !== -1) {
            return key.replace('_message', '').replace('_title', '');
        }
        const keyFull = 'Toast.' + key;
        const translation: string = t(keyFull, params) as string;
        if (translation === keyFull) {
            return t('Toast.generalError');
        } else {
            return translation;
        }
    };

    return (
        <div
            className={classNames(
                styles.notificationContainer,
                styles.topCenter,
                { [styles.hidden]: !toastData }
            )}
        >
            {!!toastData && (
                <div
                    className={classNames(
                        styles.notification,
                        styles.toast,
                        styles.topCenter,
                        styles[getMessageType(toastData.messageType)]
                    )}
                >
                    <button
                        className={styles.delete}
                        onClick={() => deleteToast()}
                    >
                        <Icon icon={CancelIcon} />
                    </button>
                    <div className={styles.notificationImage}>
                        <Icon icon={getIcon(toastData.messageType)} />
                    </div>
                    <div>
                        <p
                            className={classNames(styles.notificationTitle, {
                                [styles.withBorder]: !!toastData.message,
                            })}
                        >
                            {toastData.messageTitle
                                ? toastData.translated
                                    ? toastData.messageTitle
                                    : getToastTranslation(
                                          toastData.messageTitle,
                                          toastData.titleParams
                                      )
                                : ''}
                        </p>
                        <div className={styles.notificationMessage}>
                            <ReactMarkdown>
                                {toastData.message
                                    ? toastData.translated
                                        ? toastData.message
                                        : getToastTranslation(
                                              toastData.message,
                                              toastData.messageParams
                                          )
                                    : ''}
                            </ReactMarkdown>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Toast;
