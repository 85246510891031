import React, { ReactElement } from 'react';
import classNames from 'classnames';
import styles from './Text.module.scss';

interface Props {
    weight?: 'light' | 'medium' | 'semibold';
    size?: 'small' | 'medium' | 'large';
    uppercase?: boolean;
    block?: boolean;
    className?: string;
    title?: string;
    children: string;
}

const Text = ({
    weight,
    size,
    block,
    className,
    children,
    uppercase,
    title,
}: Props): ReactElement => (
    <span
        className={classNames(className, styles.root, {
            [styles.medium]: weight === 'medium',
            [styles.semibold]: weight === 'semibold',
            [styles.small]: size === 'small',
            [styles.large]: size === 'large',
            [styles.block]: block,
            [styles.uppercase]: uppercase,
        })}
        title={title}
    >
        {children}
    </span>
);

export default Text;
