import { AxiosResponse } from 'axios';
import { MessageType, pushMessage } from 'components/layouts/Toast';
import { logout } from 'utils/helpers/browser/login';

export interface ApiResponseInterface {
    ok?: boolean;
    error?: string | string[];
    errors?: any;
    message?: string;
    data?: any;
}

export interface ApiDataResponseInterface<TData> {
    ok?: boolean;
    error?: string | string[];
    errors?: any;
    message?: string;
    data: TData;
}

export const getResponse = async <ResponseType extends ApiResponseInterface>(
    response: AxiosResponse,
    full?: boolean,
    enableToastError = true,
    allDataWithMessage = false
): Promise<ResponseType> => {
    if (!response) {
        if (enableToastError) pushMessage('generalError', MessageType.DANGER);
        //@ts-ignore-next-line
        return {
            error: 'no_code',
        };
    }

    if (response.status === 200 || response.status === 201) {
        if (response.data?.message) {
            if (allDataWithMessage) {
                return {
                    ...response.data,
                };
            }
            //@ts-ignore-next-line
            return {
                message: response.data.message,
            };
        }
        //@ts-ignore-next-line
        return {
            data: full ? response.data : response.data?.data,
        };
    } else if (response.status === 204) {
        //@ts-ignore-next-line
        return {
            data: null,
        };
    } else if (response.status === 401) {
        if (response.data?.message === 'user_locked') {
            pushMessage(
                'user_locked_message',
                MessageType.DANGER,
                'user_locked_title'
            );
            await logout();
        } else if (
            ['token_invalid_or_expired', 'token_missing'].includes(
                response.data?.message
            )
        ) {
            await logout();
        } else {
            pushMessage(
                'not_access_action_message',
                MessageType.DANGER,
                'not_access_message_title'
            );
            const code = response.data?.data?.code;
            //@ts-ignore-next-line
            return {
                status: response.status,
                error: code || 'no_code',
                errors: response.data?.data?.errors,
            };
        }
    } else if (response.status === 404) {
        const code = response.data?.data?.code;
        if (code) {
            pushMessage(code, MessageType.DANGER);

            //@ts-ignore-next-line
            return {
                error: code,
            };
        }

        if (enableToastError)
            pushMessage('middlewareMissing', MessageType.DANGER);
        //@ts-ignore-next-line
        return {
            error: 'middlewareMissing',
        };
    } else if (response.status === 422) {
        const code = response.data?.data?.code as string;
        if (enableToastError)
            pushMessage(
                (code && !code.includes(' ') ? code : 'validaton_error') +
                    '_message',
                MessageType.DANGER
            );
        //@ts-ignore-next-line
        return {
            status: response.status,
            error: code || 'no_code',
            errors: response.data?.data?.errors,
        };
    } else if (response.status === 429) {
        if (enableToastError)
            pushMessage('too_many_requests_message', MessageType.DANGER);
        //@ts-ignore-next-line
        return {
            status: response.status,
            message: response.statusText,
            error: 'Too Many Requests',
        };
    } else if (response.status === 503 || response.status === 502) {
        if (enableToastError)
            pushMessage('service_unavailable_message', MessageType.WARNING);

        //@ts-ignore-next-line
        return {
            error: 'Service Unavailable',
        };
    } else {
        const code = response.data?.data?.code;
        if (enableToastError)
            pushMessage(
                code ? code + '_message' : 'generalError',
                MessageType.DANGER
            );
        //@ts-ignore-next-line
        return {
            error: code || 'no_code',
            errors: response.data?.data?.errors,
        };
    }
};
