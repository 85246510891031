import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Modal from './Modal';
import Button from 'components/forms/Button';
import Alert from 'components/icon/icons/alert.svg';
import styles from 'components/modal/Modal.module.scss';
import { useTranslation } from 'utils/localization';
import {
    getTokenCookie,
    removeTokenCookie,
} from 'utils/helpers/browser/cookies';
import { getJwtExpiration, TokenPurpose } from 'utils/helpers/cookies.shared';
import AuthService from 'services/auth.service';
import { logout } from 'utils/helpers/browser/login';
import { UserService } from 'services/user.service';

const LogoutModal = () => {
    const { t } = useTranslation();

    // Is dialogue currently shown? If the dialogue is shown, there is a timer
    // to log out a user.
    const [show, setShow] = useState<boolean>(false);

    const [reloadTimerCounter, setReloadTimerCounter] = useState<number>(0);

    // How long should a dialogue be displayed before the refresh token expiration in minutes.
    const dialogDurationMin = 1;

    function getRefreshTokenExpiration(): number | null {
        const refreshToken = getTokenCookie(TokenPurpose.Refresh);
        if (!refreshToken) return null;
        const expiration = getJwtExpiration(refreshToken);
        return expiration ? expiration.getTime() : null;
    }

    // Set up the timer to display the dialogue `dialogDurationMin` minutes
    // before login expiration.
    useEffect(() => {
        // Keep expiration in closure, so it can be referenced from timeout callback.
        const tokenExpiration = getRefreshTokenExpiration();

        // If there is no expiration, user is not logged in. In such a case, don't display dialogue.
        // Login should only happen when page is reloaded, because it needs to set cookies.
        if (!tokenExpiration) return;

        const minMs = Math.max(
            tokenExpiration - Date.now() - dialogDurationMin * 60_000,
            0
        );

        const timer = setTimeout(
            (timerCounter, tokenExpiration) => {
                if (timerCounter != reloadTimerCounter) {
                    return;
                }

                // Did expiration change? If so, redo the loop, because we might
                // have extended login before timer run out. Change in expiration\
                // will redo this useEffect method.
                const currentTokenExpiration = getRefreshTokenExpiration();
                if (currentTokenExpiration != tokenExpiration) {
                    setReloadTimerCounter((c) => c + 1);
                    return;
                }

                setShow(true);
            },
            minMs,
            reloadTimerCounter,
            tokenExpiration
        );
        return () => clearTimeout(timer);
    }, [reloadTimerCounter]);

    // When dialogue is shown, set up timer to actually log out the user.
    useEffect(() => {
        // If dialogue is hidden, don't set up the timer.
        if (!show) return;

        // 5 seconds before login expires, log out the user. At that time, the
        // dialogue has been displayed `dialogDurationMin` minutes.
        const timer = setTimeout(() => {
            if (!show) {
                return;
            }

            setShow(false);
            new AuthService().logout().finally(async () => {
                await logout();
            });
        }, dialogDurationMin * 60_000 - 5000);

        return () => clearTimeout(timer);
    }, [show]);

    // Close the dialogue and extend
    const onExtend = async () => {
        setShow(false);
        // Call a request to the backend. Any request without access token prolongs refresh token.
        removeTokenCookie(TokenPurpose.Access);
        await new UserService().me();
        setReloadTimerCounter((c) => c + 1);
    };

    const extendText = t('user.logoutModalExtendButton');
    return (
        <Modal
            show={show}
            badge={{
                background: 'warning',
                icon: Alert,
            }}
            centerTitle
            title="user.logoutModalTitle"
            isAlertModal
        >
            <div className={styles.modalContent}>
                <span className={classNames(styles.modalText, {})}>
                    {t('user.logoutModalText', { minutes: 1 })}
                </span>
                <div className={styles.modalButtons}>
                    <Button onClick={onExtend} resolveImmediately={true}>
                        {extendText}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default LogoutModal;
