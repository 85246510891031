import React, { KeyboardEvent, ReactElement, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { TFunction } from 'next-i18next';
import classNames from 'classnames';
import { BadgeBackground, DialogStatus } from 'types/types';
import Card from 'components/card/Card';
import Icon from 'components/icon/Icon';
import Check from 'components/icon/icons/green_check.svg';
import Loading from 'components/loading/Loading';
import { isBrowser } from 'utils/index';
import Cross from 'components/modal/Cross';
import { useTranslation } from 'utils/localization';
import { Title } from 'components/typography';
import styles from './Modal.module.scss';

type ModalSize = 'S' | 'M' | 'L' | 'MXL' | 'XL' | 'FULL';

interface Props {
    children: React.ReactChild;
    badge?: {
        background: BadgeBackground;
        icon: React.FC<React.SVGProps<SVGSVGElement>>;
    };
    onHide?: () => void;
    show: boolean;
    status?: DialogStatus;
    loadingText?: string;
    successText?: string;
    className?: string;
    classNameTitle?: string;
    isAlertModal?: boolean;
    hideAfterSuccess?: boolean;
    showCross?: boolean;
    size?: ModalSize;
    title?: string | ReactElement;
    centerTitle?: boolean;
    biggerTitle?: boolean;
    isNotClosable?: boolean;
    isTitleTranslated?: boolean;
}

const getTitle = (
    t: TFunction,
    title: string | ReactElement,
    isTranslated: boolean
) => {
    if (title) {
        if (isTranslated || typeof title !== 'string') {
            return title;
        }

        return t(title);
    }

    return undefined;
};

const Modal = ({
    children,
    badge,
    onHide,
    show,
    status,
    loadingText,
    successText,
    className,
    classNameTitle,
    isAlertModal,
    hideAfterSuccess,
    showCross,
    size = 'L',
    title,
    centerTitle,
    biggerTitle = false,
    isNotClosable = false,
    isTitleTranslated = false,
}: Props): ReactElement => {
    const { t } = useTranslation();
    const [modalElementExists, setModalElementExists] = useState(false);

    if (hideAfterSuccess && status === 'success') {
        setTimeout(() => {
            if (typeof onHide === 'function') {
                onHide();
            }
        }, 2000);
    }

    useEffect(() => {
        if (show) {
            document.body.classList.add(styles.noScroll);
            setModalElementExists(
                document.getElementById('modal') != undefined
            );
        } else {
            document.body.classList.remove(styles.noScroll);
            setModalElementExists(false);
        }
    }, [show]);

    useEffect(() => {
        return () => {
            document.body.classList.remove(styles.noScroll);
        };
    }, []);

    const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) =>
        e.code === 'Escape' && typeof onHide === 'function' && onHide();

    return (
        modalElementExists &&
        isBrowser() &&
        ReactDOM.createPortal(
            show && (
                <div className={styles.root} onKeyDown={onKeyDown}>
                    <div
                        className={styles.overlay}
                        onClick={() =>
                            !isNotClosable && typeof onHide === 'function'
                                ? onHide()
                                : null
                        }
                    />
                    <div
                        className={
                            isAlertModal
                                ? styles.cardWrapperAlert
                                : styles['cardWrapper' + size]
                        }
                        onClick={() =>
                            !isNotClosable && typeof onHide === 'function'
                                ? onHide()
                                : null
                        }
                    >
                        <Card
                            className={classNames(styles.card, className)}
                            badge={badge}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <>
                                {(title || showCross) && (
                                    <div className={styles.header}>
                                        <div
                                            className={classNames(
                                                styles.title,
                                                {
                                                    [styles.modalTitleCenter]:
                                                        centerTitle,
                                                    [styles.bigger]:
                                                        isAlertModal,
                                                }
                                            )}
                                        >
                                            <div
                                                className={classNames(
                                                    classNameTitle,
                                                    {
                                                        [styles.modalTitle]:
                                                            !isAlertModal,
                                                    }
                                                )}
                                            >
                                                <Title
                                                    className={classNames({
                                                        [styles.biggerTitle]:
                                                            biggerTitle,
                                                    })}
                                                >
                                                    {getTitle(
                                                        t,
                                                        title,
                                                        isTitleTranslated
                                                    )}
                                                </Title>
                                            </div>
                                            {showCross && (
                                                <div
                                                    className={
                                                        styles.crossBlock
                                                    }
                                                >
                                                    <Cross cb={onHide} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className={styles.body}>
                                    {(!status ||
                                        status === 'default' ||
                                        status === 'error') &&
                                        children}
                                    {status === 'success' && (
                                        <div className={styles.centerContent}>
                                            <div>
                                                <div
                                                    className={
                                                        styles.dialogSuccessIcon
                                                    }
                                                >
                                                    <Icon icon={Check} />
                                                </div>
                                                <span
                                                    className={styles.modalText}
                                                >
                                                    {successText}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {status === 'loading' && (
                                        <div className={styles.centerContent}>
                                            <Loading
                                                loadingText={loadingText}
                                            />
                                        </div>
                                    )}
                                </div>
                            </>
                        </Card>
                    </div>
                </div>
            ),
            document.getElementById('modal')
        )
    );
};

export default Modal;
