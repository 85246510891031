import { IncomingMessage } from 'http';
import { NextApiRequestQuery } from 'next/dist/server/api-utils';
import { NextApiRequest, NextApiResponse } from 'next';
import { TokenPurpose, getTokenName } from '../cookies.shared';
import { DisciplineEnum } from 'types/types';

const REDIRECT_COUNTER_NAME = 'redirect_counter';
const EXPIRED_COOKIE_VALUE = 'expired';

/**
 * Searches HTTP request for a cookie
 */
export const getCookieFromRequest = (
    req: IncomingMessage,
    name: string
): string | null => {
    if (req.headers.cookie?.includes(name)) {
        let response: string = req.headers.cookie
            .split(';')
            .filter((item) => item.trim().startsWith(name + '='))
            .toString();
        response = response.trim().slice(name.length + 1);
        return response;
    }
    return null;
};

/**
 * Searches HTTP response for a cookie
 */
export const getCookieFromResponse = (
    res: NextApiResponse,
    name: string
): string | null => {
    const cookies = getCookiesInResponse(res);

    // Iterate over cookies
    for (const cookie of cookies) {
        const value = getCookieValueByName(cookie, name);
        if (value) {
            return value;
        }
    }

    return null;
};

/**
 * Tries to get a cookie from the response and then from the request.
 * Return null if the cookie could not be found or has been invalidated.
 */
export const getCookieFromContext = (
    req: IncomingMessage,
    res: NextApiResponse,
    name: string
): string | null => {
    const responseValue = getCookieFromResponse(res, name);
    if (responseValue == EXPIRED_COOKIE_VALUE) {
        return null;
    }
    if (responseValue) {
        return responseValue;
    }

    const requestValue = getCookieFromRequest(req, name);
    if (requestValue) {
        return requestValue;
    }

    return null;
};

/**
 * Returns cookies value by its name from cookies string.
 * If the cookie has no been found, returns null.
 */
function getCookieValueByName(cookieString: string, name: string) {
    const match = cookieString.match(
        // eslint-disable-next-line security/detect-non-literal-regexp
        new RegExp('(^| )' + name + '=([^;]+)')
    );
    return match ? match[2] : null;
}

/**
 * Returns a list of cookies in the HTTP response.
 * Always returns a list.
 */
function getCookiesInResponse(res: NextApiResponse): string[] {
    let cookieHeader = res.getHeader('Set-Cookie');

    // No header
    if (!cookieHeader) {
        return [];
    }

    // Convert to array if not an array already
    if (!Array.isArray(cookieHeader)) {
        cookieHeader = [cookieHeader as string];
    }

    return cookieHeader;
}

/**
 * Sets a cookies in the HTTP response with expored time and "expired" value
 */
export const invalidateCookieInResponse = (
    res: NextApiResponse,
    cookieName: string
): void => {
    // Get current list of cookies
    const cookieHeader = getCookiesInResponse(res);

    // Add the invalid cookie
    cookieHeader.push(
        `${cookieName}=${EXPIRED_COOKIE_VALUE}; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`
    );

    // Save it
    res.setHeader('Set-Cookie', cookieHeader);
};

/**
 * Sets a cookie in the HTTP response via the Set-Cookie header
 */
export const setCookieInResponse = (
    res: NextApiResponse,
    cookieName: string,
    cookieValue: string,
    expiration: Date
): void => {
    if (!cookieValue) {
        return;
    }

    // Get current list of cookies
    const cookieHeader = getCookiesInResponse(res);

    // Add the new cookie
    cookieHeader.push(
        `${cookieName}=${cookieValue}; path=/; expires=${expiration.toUTCString()}; Secure`
    );

    // Save it
    res.setHeader('Set-Cookie', cookieHeader);
};

/**
 * Searches the HTTP request in
 * 1. query
 * 2. cookies
 * and tries to find a discipline
 */
export const getDisciplineCookieFromRequest = (
    req: NextApiRequest,
    query: NextApiRequestQuery
): DisciplineEnum => {
    if (
        query['discipline'] &&
        (<any>Object)
            .values(DisciplineEnum)
            .includes(query['discipline'] as string)
    ) {
        return query['discipline'] as DisciplineEnum;
    }
    return (
        (getCookieFromRequest(req, 'discipline') as DisciplineEnum) ||
        DisciplineEnum.football
    );
};

/**
 * Searches headers in the HTTP request and extracts a token cookie.
 * Returns null if not found.
 */
export const getTokenCookieFromRequest = (
    req: IncomingMessage,
    purpose: TokenPurpose
): string | null => {
    const cookieName = getTokenName(purpose);
    return getCookieFromRequest(req, cookieName);
};

/**
 * Searches headers in the HTTP response and extracts a token cookie.
 * Returns null if not found.
 */
export const getTokenCookieFromResponse = (
    res: NextApiResponse,
    purpose: TokenPurpose
): string | null => {
    const cookieName = getTokenName(purpose);
    return getCookieFromResponse(res, cookieName);
};

/**
 * Searches headers in the HTTP response and then HTTP request to extract a token cookie.
 * Returns null if not found.
 */
export const getTokenCookieFromContext = (
    req: IncomingMessage,
    res: NextApiResponse,
    purpose: TokenPurpose
): string | null => {
    const cookieName = getTokenName(purpose);
    return getCookieFromContext(req, res, cookieName);
};

/**
 * Searches headers in the HTTP request and extracts the redirect cookie.
 * Returns null if not found.
 */
export const getRedirectCookieFromRequest = (
    req: IncomingMessage
): string | null => {
    return getCookieFromRequest(req, REDIRECT_COUNTER_NAME);
};
