import Axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { logout } from './login';
import { getDiscipline } from './discipline';
import { MessageType, pushMessage } from 'components/layouts/Toast';

type Method = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';

/**
 * @deprecated Use the newFetcher.ts instead
 */
export default async function fetcher(
    url: string,
    method: Method = 'GET',
    params: any = null,
    body: any = null,
    timeout = 30000,
    disableLogout = false
): Promise<AxiosResponse> {
    let finalUrl = url;

    // Ensure that url contains the domain
    if (finalUrl.includes('://') === false) {
        const location = window.location;
        finalUrl = `${location.protocol}//${location.host}${finalUrl}`;
    }

    const axiosConfig: AxiosRequestConfig = {
        url: url,
        method: method,
        data: body,
        timeout: timeout || 30000,
        params: { discipline: getDiscipline() },
    };

    if (params && Object.keys(params).length !== 0) {
        axiosConfig.params = { ...axiosConfig.params, ...params };
    }

    // Request to NextJS API
    try {
        return await Axios.request(axiosConfig);
    } catch (error: any) {
        if (error.response) {
            if (error.response.status === 401) {
                if (error.response.data?.message === 'user_locked') {
                    pushMessage(
                        'error.userLocked',
                        MessageType.DANGER,
                        undefined,
                        true
                    );
                }
                if (!disableLogout) {
                    await logout();
                }
            } else if (error.response.status === 404) {
                // @TODO Redirect to 404? Or just flash message?
            }
        }
        return error.response;
    }
}
