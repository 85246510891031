import React, { useState, useEffect, ReactElement } from 'react';
import { appWithTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { AppProps } from 'next/app';
import * as Sentry from '@sentry/node';
import '../styles.scss';
import * as nextI18NextConfig from '../next-i18next.config';
import TopBanner from 'components/layouts/TopBanner';
import TopProgressBar from 'components/layouts/TopProgressBar';
import {
    createLocationSearchWithDiscipline,
    getDiscipline,
    setDiscipline,
} from 'utils/helpers/browser/discipline';
import { DisciplineEnum, LangEnum } from 'types/types';
import { isBrowser } from 'utils';
import { getSentryDSN } from 'utils/helpers/helpers';
import { UserMe } from 'services/user.service';
import { MenuApiEnum } from 'components/interfaces/GeneralInterface';
import LogoutModal from 'components/modal/LogoutModal';

const SENTRY_DSN = getSentryDSN();
if (SENTRY_DSN && process.env.NODE_ENV === 'production') {
    Sentry.init({
        enabled: true,
        dsn: SENTRY_DSN,
    });
}

interface MyAppProps extends GlobalContextType {
    err: Error;
}

export interface GlobalContextType {
    user: UserMe | null;
    menu: MenuApiEnum[];
    nameSpaces: string[];
    lang: LangEnum;
    setLang: (lang: LangEnum) => void;
}

export const GlobalContext = React.createContext<GlobalContextType>({
    user: null,
    menu: [],
    nameSpaces: [],
    lang: LangEnum.cs,
    setLang: () => undefined,
});

// const FRESHDESK_URL =
//     'https://euc-widget.freshworks.com/widgets/80000005153.js';

const MyApp = ({
    Component,
    pageProps,
}: AppProps<MyAppProps>): ReactElement => {
    const Router = useRouter();
    const discipline = getDiscipline();
    const [lang, setLang] = useState(pageProps.lang);
    // const [loaded, setLoaded] = useState(false);

    const isExportPage =
        isBrowser() && window.location.pathname.includes('export');

    useEffect(() => {
        setLang(pageProps.lang);
    }, [pageProps.lang]);

    // useEffect(() => {
    //     if (isBrowser() && !isExportPage && loaded) {
    //         if ('function' == typeof window.FreshworksWidget) {
    //             window.FreshworksWidget('destroy');

    //             window.fwSettings = {
    //                 ...window.fwSettings,
    //                 locale: lang,
    //             };

    //             const oldScript = document.querySelector(
    //                 `script[src="${FRESHDESK_URL}"]`
    //             );
    //             if (oldScript) oldScript.remove();

    //             const script = document.createElement('script');
    //             script.async = true;
    //             script.defer = true;
    //             script.src = FRESHDESK_URL;
    //             document.head.appendChild(script);
    //         } else {
    //             const script = document.createElement('script');
    //             script.async = true;
    //             script.src = '/scripts/freshdesk.js';
    //             document.head.appendChild(script);
    //             script.addEventListener('load', () => {
    //                 const script = document.createElement('script');
    //                 script.async = true;
    //                 script.defer = true;
    //                 script.src = FRESHDESK_URL;
    //                 document.head.appendChild(script);
    //             });
    //         }
    //     }
    // }, [lang, loaded]);

    useEffect(() => {
        // setLoaded(true);
        window.pxToRem = (px: number): string => {
            const fontSizes = {
                '3': '$fontSizeTitle1',
                '1.875': '$fontSizeTitle2',
                '1.5': '$fontSizeTitle3',
                '1.437': '$fontSizeBiggestText',
                '1.25': '$fontSizeBiggerText',
                '1': '$fontSizeText',
                '0.875': '$fontSizeSmallText',
                '0.8': '$fontSizeSmallestText',
            };
            const result = px / 16;
            //@ts-ignore-next-line
            if (fontSizes['' + result]) {
                //@ts-ignore-next-line
                copy(fontSizes['' + result]);
            } else {
                //@ts-ignore-next-line
                copy('' + result + 'rem');
            }
            return `
                ${
                    //@ts-ignore-next-line
                    fontSizes['' + result]
                        ? `### COPIED (${
                              //@ts-ignore-next-line
                              fontSizes['' + result]
                          })!`
                        : `### COPIED (${result}rem)!`
                }
                [${result}]
                ${Object.keys(fontSizes)
                    .map((key) => {
                        //@ts-ignore-next-line
                        return `${fontSizes[`${key}`]}: ${key}rem`;
                    })
                    .join('\n')}
                $fontSizeButton: 1rem;
            `;
        };

        if (
            Router.query.discipline &&
            Router.query.discipline !== discipline &&
            Object.values(DisciplineEnum).includes(
                Router.query.discipline as DisciplineEnum
            )
        ) {
            setDiscipline(Router.query.discipline as DisciplineEnum);
            Router.reload();
        } else if (
            Router.query.discipline &&
            Router.query.discipline === discipline
        ) {
            return;
        } else {
            const search = createLocationSearchWithDiscipline(discipline);
            window.history.replaceState(
                window.history.state,
                '',
                `${window.location.pathname}${search}`
            );
        }
    }, []);

    return (
        <GlobalContext.Provider
            value={{
                user: pageProps.user,
                menu: pageProps.menu,
                nameSpaces: pageProps.nameSpaces,
                lang,
                setLang: (lang) => setLang(lang),
            }}
        >
            <TopProgressBar />
            <LogoutModal />
            <TopBanner isExportPage={isExportPage} />
            <Component {...pageProps} />
        </GlobalContext.Provider>
    );
};

export default appWithTranslation(MyApp as any, nextI18NextConfig as any);
