import React, { ReactElement } from 'react';
import classNames from 'classnames';
import styles from './H2.module.scss';

interface Props {
    children?: React.ReactChild;
    className?: string;
    smaller?: boolean;
}

const H2 = (props: Props): ReactElement => {
    const { children, className, smaller } = props;

    return (
        <h2
            className={classNames(styles.root, className, {
                [styles.smaller]: smaller,
            })}
        >
            {children}
        </h2>
    );
};

export default H2;
