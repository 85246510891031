import React, { MouseEvent, ReactElement } from 'react';
import Button, { ButtonProps } from 'components/forms/Button';
import styles from './ContextMenu.module.scss';

interface Props {
    handleOpen: any;
    isButton?: boolean;
    resolveButtonImmediately?: boolean;
    children: React.ReactChild;
    buttonProps?: ButtonProps;
}

const ContextMenuInner = ({
    handleOpen,
    isButton,
    children,
    buttonProps = {},
    resolveButtonImmediately,
}: Props): ReactElement => {
    if (isButton) {
        const onClick = (e: MouseEvent<Element>) => {
            handleOpen(e);
            buttonProps.onClick && buttonProps.onClick(e);
        };

        return (
            <Button
                anyWidth
                {...buttonProps}
                onClick={onClick}
                resolveImmediately={resolveButtonImmediately}
            >
                {children}
            </Button>
        );
    } else {
        return (
            <div className={styles.iconWrap} onClick={handleOpen}>
                {children}
            </div>
        );
    }
};

export default ContextMenuInner;
