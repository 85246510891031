import { AxiosResponse } from 'axios';
import { UserMe } from './user.service';
import fetcher from 'utils/helpers/browser/fetcher';

interface LoginRequestData {
    email: string;
    password: string;
}

interface LoginResponseData {
    access_token?: string;
    refresh_token?: string;
    user?: UserMe;
    error?: string;
}

interface SwitchRequestData {
    person_id: number;
}

interface SwitchResponseData {
    access_token?: string;
    error?: string;
}

export default class AuthService {
    async login(data: LoginRequestData): Promise<LoginResponseData> {
        const response: AxiosResponse = await fetcher(
            '/api/auth/login',
            'POST',
            null,
            data,
            undefined,
            true
        );

        if (response.status === 200) {
            const json = await response.data;
            return {
                access_token: json.access_token,
                refresh_token: json.refresh_token,
                user: json.user,
            };
        } else {
            return {
                error: response.data?.data?.code,
            };
        }
    }

    async switchUserPerson(
        data: SwitchRequestData
    ): Promise<SwitchResponseData> {
        const response: AxiosResponse = await fetcher(
            '/api/auth/switch',
            'POST',
            null,
            data
        );

        if (response.status === 200) {
            const json = response.data;
            return {
                access_token: json.access_token,
            };
        } else {
            return {
                error: response.data?.data?.code,
            };
        }
    }

    async logout(): Promise<void> {
        await fetcher('/api/auth/logout', 'POST');
    }
}
