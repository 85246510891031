import Router from 'next/router';
import { TokenPurpose } from '../cookies.shared';
import {
    setTokenCookie,
    removeRedirectCookie,
    removeTokenCookies,
} from './cookies';

export const login = (accessToken: string, refreshToken: string): void => {
    setTokenCookie(accessToken, TokenPurpose.Access);
    setTokenCookie(refreshToken, TokenPurpose.Refresh);
    removeRedirectCookie();
};

export const logout = async (): Promise<void> => {
    removeTokenCookies();

    await Router.push('/' + window.location.search);
};
