import React, { ReactElement, useState } from 'react';
import CardIcon, { InputActionInterface } from 'components/card/CardIcon';
import Input, { InputType } from 'components/forms/Input';
import Tooltip from 'components/layouts/Tooltip';
import {
    isNumberField,
    validateNumberMinMax,
} from 'utils/helpers/validateNumber';
import NumberInput from 'components/forms/NumberInput';
import { useTranslation } from 'utils/localization';
import styles from './CardIconInput.module.scss';

interface Props {
    type: 'text' | 'number';
    actions: InputActionInterface;
}

const CardIconInput = (props: Props): ReactElement => {
    const {
        type,
        actions: { confirmLabelKey, initValue, onClick },
    } = props;
    const { t } = useTranslation();
    const [value, setValue] = useState<string | number>(initValue);
    const [error, setError] = useState<string>();

    const onChange = (value: string) => {
        if (type === 'number') {
            value = value.length < 4 ? value : value.slice(0, 3);
            const numberError = isNumberField(t, value);
            if (!numberError) {
                const checkMinMaxError = validateNumberMinMax(
                    t,
                    Number(value),
                    1,
                    999
                );
                if (checkMinMaxError) {
                    setError(checkMinMaxError);
                } else {
                    setError(null);
                }
            } else {
                setError(numberError);
            }
        }

        setValue(value);
    };

    const getInput = (type: 'text' | 'number') => {
        switch (type) {
            case 'text':
                return (
                    <Input
                        type={InputType.text}
                        value={value as string}
                        onChange={(e) => onChange(e.target.value + '')}
                    />
                );
            case 'number':
                return (
                    <NumberInput
                        min={1}
                        max={999}
                        value={value === '' ? value : Number(value)}
                        onChange={(val) =>
                            onChange(val === '' ? val : val.toString())
                        }
                        hideArrows
                    />
                );
        }
    };

    return (
        <>
            <div className={styles.input}>{getInput(type)}</div>
            <div>
                <Tooltip label={error} hideTooltip={!error}>
                    <CardIcon
                        action={{
                            type: 'other',
                            labelKey: confirmLabelKey,
                            onClick: !error ? () => onClick(value) : undefined,
                        }}
                        className={styles.positionR}
                    />
                </Tooltip>
            </div>
        </>
    );
};

export default CardIconInput;
