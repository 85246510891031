import React, { ReactElement } from 'react';
import classNames from 'classnames';
import styles from './Title.module.scss';

interface Props {
    children?: React.ReactChild;
    className?: string;
}

const Title = (props: Props): ReactElement => {
    const { children, className } = props;

    return (
        <span className={classNames(styles.root, className)}>{children}</span>
    );
};

export default Title;
