import { TFunction } from 'i18next';
import { NextRouter } from 'next/router';
import { DisciplineEnum, RegistrationType } from 'types/types';

export const registrationTypeToString = (
    t: TFunction,
    type: RegistrationType
): string => {
    return t(`playerCompare.${type}`);
};

export const getDynamicPathName = (router: NextRouter): string => {
    const regex = /\[(.*?)\]/;
    let pathName = router.pathname;
    while (regex.test(pathName)) {
        pathName = pathName.replace(regex, (_, b) => '' + router.query[b]);
    }
    return pathName;
};

/** Translates an output of {@link validateEmailFn} */
export const validateEmail = (t: TFunction, value: string): string => {
    return validateEmailFn(value) ? undefined : t('validations.emailNotValid');
};

export const validateEmailFn = (value: string): boolean => {
    const re =
        /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/;
    return re.test(String(value).toLowerCase());
};

export const validatePostalCode = (value: string, t: TFunction): string => {
    if (!value) return t('validations.postalCodeNotValid');
    if (/^[0-9]{3}[ ][0-9]{2}$/.test(value))
        return t('validations.canNotContainPostalCode');
    const re = /^[0-9]{3}[0-9]{2}$/;
    return re.test(value) ? undefined : t('validations.postalCodeNotValid');
};

export const validateNotRequiredPhone = (
    value: string,
    validateCzechPhoneFormat: boolean,
    t: TFunction
): string =>
    validateCzechPhoneFormat
        ? validateNotRequiredCzechPhone(String(value))
            ? undefined
            : validatePhoneNoState(String(value))
            ? t('validations.phoneNotValidState')
            : t('validations.phoneNotValid')
        : validatePhoneNoCzState(String(value))
        ? undefined
        : t('validations.phoneNotValid');

export const validatePhone = (
    value: string,
    validateCzechPhoneFormat: boolean,
    t: TFunction
): string =>
    validateCzechPhoneFormat
        ? validateCzechPhone(String(value))
            ? undefined
            : validatePhoneNoState(String(value))
            ? t('validations.phoneNotValidState')
            : t('validations.phoneNotValid')
        : validatePhoneNoCzState(String(value))
        ? undefined
        : t('validations.phoneNotValid');

export const validateNotRequiredCzechPhone = (value: string): boolean => {
    if (!value) return false;
    const re = /^(\+420|00420|\+421|00421)[1-9][0-9]{8}$/;
    const onlyPrefix = /^(\+420|00420|\+421|00421)$/;
    const nspsValue = value.replace(/\u0020+/g, '');
    return re.test(nspsValue) || onlyPrefix.test(nspsValue);
};

export const validateCzechPhone = (value: string): boolean => {
    if (!value) return false;
    const re = /^(\+420|00420|\+421|00421)[1-9][0-9]{8}$/;
    return re.test(value.replace(/\u0020+/g, ''));
};

export const validatePhoneNoCzState = (value: string): boolean => {
    if (!value) return false;
    const re =
        /(^(\+420|00420|\+421|00421)[1-9][0-9]{8}$)|(^(\+|00)(?!42[01])[0-9]{4,14}$)/;
    return re.test(value.replace(/\u0020+/g, ''));
};

export const validatePhoneNoState = (value: string): boolean => {
    if (!value) return false;
    const re = /^[1-9][0-9]{8}$/;
    return re.test(value.replace(/\u0020+/g, ''));
};

export const validatePhoneAny = (value: string): boolean => {
    if (!value) return false;
    const re = /^[0-9+*\-(][0-9/()\- ]*$/;
    return re.test(value);
};

export const validateNoWhitespaceOnly = (
    t: TFunction,
    value: string
): string | undefined => {
    return value.trim() ? undefined : t('validations.noWhitespaceOnly');
};

export const convertArrayToObject = (
    array: any[],
    key: string,
    valueKey?: string,
    value?: string
): { [key: string]: any } => {
    const initialValue = {};
    return array.reduce((obj, item) => {
        return {
            ...obj,
            [item[key]]: valueKey
                ? item[valueKey]
                : typeof value !== 'undefined'
                ? value
                : item,
        };
    }, initialValue);
};

export const validatePassword = (value: string, t: TFunction): string => {
    if (/[À-ž]/.test(value)) return t('validations.diacriticsForbidden');
    if (!/[a-z]/.test(value)) return t('validations.missingSmallLetter');
    if (!/[A-Z]/.test(value)) return t('validations.missingBigLetter');
    if (!/[0-9]/.test(value)) return t('validations.missingNumber');
    if (!/[\W|_]/.test(value)) return t('validations.missingSpecialChar');
    if (value.length < 8) return t('validations.atLeastEight');
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{8,}$/.test(value))
        return t('validations.passwordNotValid');

    return undefined;
};

export const disciplineTypeToString = (discipline: DisciplineEnum): string => {
    return `disciplines.${discipline}`;
};

export const getAgeFromDate = (today: Date, birthDate: Date): number => {
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate() + 1)) {
        age--;
    }
    return age;
};

export const getAge = (birthDate: Date): number =>
    getAgeFromDate(new Date(), birthDate);

export const isAboveAge = (birthDate: Date, ageLimit: number): boolean => {
    return getAge(birthDate) >= ageLimit;
};

export const isAbove18 = (birthDate: Date): boolean =>
    isAboveAge(birthDate, 18);

export const isAbove15 = (birthDate: Date): boolean =>
    isAboveAge(birthDate, 15);

export const getGradientColors = (colors: string[]): string[] => {
    if (!colors || colors.length === 0) return undefined;
    const l = colors.length;
    const first = colors[0];

    if (l === 1) return [first, first, first, first, first, first];
    const second = colors[1];

    if (l === 2) return [first, first, first, second, second, second];

    if (l === 6) return colors;

    const last = colors[l - 1];
    return [first, first, first, last, last, last];
};

export const shadeColor = (color: string, percent: number): string => {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = (R * (100 + percent)) / 100;
    G = (G * (100 + percent)) / 100;
    B = (B * (100 + percent)) / 100;

    R = R < 255 ? (R < 0 ? 0 : Math.round(R)) : 255;
    G = G < 255 ? (G < 0 ? 0 : Math.round(G)) : 255;
    B = B < 255 ? (B < 0 ? 0 : Math.round(B)) : 255;

    const RR =
        R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
    const GG =
        G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
    const BB =
        B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

    return '#' + RR + GG + BB;
};

export const isBrowser = (): boolean => typeof window !== 'undefined';

export const LOGIN_REDIRECT_KEY = 'login-redirect';

export const isValidURL = (url: string): boolean => {
    const re =
        /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
    return re.test(url);
};

export interface CzechBirthNumberInfo {
    valid: boolean;
    errorMessageKey?: string;
    birthDate?: Date;
    gender?: string;
}

export const parseCzechBirthNumber = (
    birthNumber: string
): CzechBirthNumberInfo => {
    // Validation source: https://www.cssz.cz/standardni-kontrola-rodneho-cisla-a-evidencniho-cisla-pojistence
    const invalid: CzechBirthNumberInfo = {
        valid: false,
        errorMessageKey: 'validations.birthNumberNotValid',
    };

    /** Removes a character from a string */
    function removeCharacter(originalString: string, position: number) {
        return (
            originalString.substr(0, position) +
            originalString.substr(position + 1, originalString.length)
        );
    }

    // Strip potential / in the birth number
    if (
        (birthNumber.length === 11 || birthNumber.length === 10) &&
        birthNumber[6] == '/'
    ) {
        birthNumber = removeCharacter(birthNumber, 6);
    }

    const length = birthNumber.length;

    // Length test (2)
    if (length < 9) {
        return invalid;
    }

    // Length test (3)
    if (length > 10) {
        return invalid;
    }

    // Check whitespace characters
    function hasWhiteSpace(s: string) {
        return /\s/g.test(s);
    }
    if (hasWhiteSpace(birthNumber)) {
        return invalid;
    }

    // Numerical test (1)
    function containsOnlyNumbers(s: string) {
        return /^\d+$/.test(s);
    }
    if (!containsOnlyNumbers(birthNumber)) {
        return invalid;
    }

    // Separate into three numbers
    const yearString = birthNumber.substr(0, 2);
    const monthString = birthNumber.substr(2, 2);
    const dayString = birthNumber.substr(4, 2);
    let postfixString = birthNumber.substr(6, 3);
    if (birthNumber.length == 10) {
        postfixString = postfixString + birthNumber[9];
    }

    let year = parseInt(yearString);
    let month = parseInt(monthString);
    let day = parseInt(dayString);
    const postfix = parseInt(postfixString);

    // Zero postfix for shorter ids (4)
    if (length == 9 && postfix == 0) {
        return invalid;
    }

    // Convert month if the gender is a female
    let gender = 'male';
    if (month > 50) {
        gender = 'female';
        month -= 50;
    }

    // Check for the "plus" flag
    let plusFlag = false;
    if (month > 20) {
        plusFlag = true;
        month -= 20;
    }

    // Check for "ecp" flag
    let ecpFlag = false;
    if (day > 40) {
        ecpFlag = true;
        day -= 40;
    }

    // Check flag incompatibility (5)
    if (plusFlag && ecpFlag) {
        return invalid;
    }

    // Check year range (6)
    if (year > 99 || year < 0) {
        return invalid;
    }

    // Year correction based on postfix length
    if (length == 9) {
        if (year > 53) year += 1800;
        else year += 1900;
    } else if (length == 10) {
        if (year > 53) year += 1900;
        else year += 2000;
    }

    // Check month value (7)
    if (month < 1 || month > 12) {
        return invalid;
    }

    // Check day value (8)
    function daysInMonth(month: number, year: number) {
        return new Date(year, month, 0).getDate();
    }
    if (day < 1 || day > daysInMonth(month, year)) {
        return invalid;
    }

    // Check date validity (9)
    const dateOfBirth = new Date(year, month - 1, day, 0, 0, 0, 0);
    const dateOfBirthTimestamp = dateOfBirth.getTime();
    const currentTimestamp = Date.now();
    if (
        isNaN(dateOfBirthTimestamp) ||
        dateOfBirthTimestamp > currentTimestamp
    ) {
        return invalid;
    }

    // Check postfix value for length 9 (10)
    if (ecpFlag && length == 9 && postfix < 600) {
        return invalid;
    }

    // Check postfix value for length 10 (11)
    if (ecpFlag && length == 10 && postfix < 6000) {
        return invalid;
    }

    // Check for division by 11 (12)
    const idAsNumber = parseInt(birthNumber);
    if (length == 10 && idAsNumber % 11 != 0) {
        return invalid;
    }

    return {
        valid: true,
        birthDate: dateOfBirth,
        gender: gender,
    };
};

export const slashCzechBirthNumber = (
    isCZFormat: boolean,
    newBirthNumber: string
): string => {
    if (isCZFormat && newBirthNumber) {
        if (
            newBirthNumber.length >= 7 &&
            !newBirthNumber.includes('/') &&
            newBirthNumber.charAt(6) !== '/'
        ) {
            return newBirthNumber.substr(0, 6) + '/' + newBirthNumber.substr(6);
        } else return newBirthNumber;
    }

    return newBirthNumber;
};

/**
 * Removes '/' from czech birth number.
 * @param birthNumber
 */
export const unslashCzechBirthNumber = (birthNumber: string): string => {
    if (birthNumber && birthNumber.includes('/')) {
        return birthNumber.replace('/', '');
    }

    return birthNumber;
};
