import { TFunction } from 'i18next';
import { MAX_INTEGER_VALUE } from 'constants/general';

export const validateNumberMinMax = (
    t: TFunction,
    value: number,
    fieldMin: number,
    fieldMax: number
): string => {
    const min = fieldMin || 0;
    const max = fieldMax || MAX_INTEGER_VALUE;

    if (value < min) {
        return t('ValidationError.min', { min: min });
    } else if (max && value > max) {
        return t('ValidationError.max', { max: max });
    }

    return undefined;
};

export const isDecimalNumber = (n: string): boolean => {
    return /^[+-]?((\d+([,.]\d*)?)|([,.]\d+))$/.test(n);
};

export const isIntegerNumber = (n: string): boolean => {
    return /^[+-]?\d+$/.test(n);
};

type InputFormatType = 'decimal' | 'integer';
export const isNumberField = (
    t: TFunction,
    value: string | number,
    type: InputFormatType = 'integer'
): string => {
    if (!value && value !== 0) {
        return undefined;
    }

    if (typeof value !== 'string') {
        value = value + '';
    }

    if (type === 'decimal' && !isDecimalNumber(value)) {
        return t('ValidationError.decimalNumber');
    } else if (type === 'integer' && !isIntegerNumber(value)) {
        return t('ValidationError.integerNumber');
    }

    return undefined;
};
