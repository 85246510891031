import React, { ReactElement } from 'react';
import classNames from 'classnames';
import styles from './ButtonLoader.module.scss';

interface Props {
    isDark?: boolean;
}

const ButtonLoader = ({ isDark }: Props): ReactElement => (
    <div className={classNames(styles.root, { [styles.isDark]: !!isDark })}>
        <div />
        <div />
        <div />
        <div />
    </div>
);

export default ButtonLoader;
