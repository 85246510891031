import React, { ReactElement } from 'react';
import classNames from 'classnames';
import Icon from 'components/icon/Icon';
import { BadgeBackground } from 'types/types';
import styles from './Badge.module.scss';

interface Props {
    background: BadgeBackground;
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    className?: string;
}

const Badge = (props: Props): ReactElement => {
    const { background, icon, className } = props;

    return (
        <div
            className={classNames(
                styles.root,
                {
                    [styles.info]: background === 'info',
                    [styles.warning]: background === 'warning',
                },
                className
            )}
        >
            <div className={styles.icon}>
                <Icon icon={icon} className={styles.icon} />
            </div>
        </div>
    );
};

export default Badge;
