import React, { ReactElement } from 'react';
import classNames from 'classnames';
import styles from './Cross.module.scss';

interface Props {
    cb?: () => void;
    className?: string;
}

const Cross = ({ cb, className }: Props): ReactElement => (
    <div className={classNames(styles.cross, className)} onClick={cb}>
        <div />
        <div />
    </div>
);

export default Cross;
