import { NextApiRequestQuery } from 'next/dist/server/api-utils';
import { DisciplineEnum } from 'types/types';
import { getCookie, setCookie } from 'utils/helpers/browser/cookies';
import { getQueryFromWindow } from 'utils/helpers/browser/getQueryFromWindow';
import { getDisciplineServer } from 'utils/helpers/server/disciplineServer';
import { getUrlAnd } from 'constants/routes';

export const setDiscipline = (discipline: DisciplineEnum): void => {
    setCookie('discipline', discipline);
};

export const getDiscipline = (
    fallbackValue = DisciplineEnum.football
): DisciplineEnum => {
    if (!process.browser) {
        return getDisciplineServer(fallbackValue);
    }
    const query: NextApiRequestQuery = getQueryFromWindow();

    if (
        query['discipline'] &&
        (<any>Object)
            .values(DisciplineEnum)
            .includes(query['discipline'] as string)
    ) {
        return query['discipline'] as DisciplineEnum;
    }

    const discipline = getCookie('discipline');
    return (discipline as DisciplineEnum) || fallbackValue;
};

export const createLocationSearchWithDiscipline = (
    discipline: string
): string => {
    let search = window.location.search;
    const regexDisciplineFootballFutsal = /([?&]discipline=)(football|futsal)/;
    const regexDiscipline = /([?&]discipline=[A-Za-z0-9_]*)/;

    if (search.match(regexDisciplineFootballFutsal)) {
        search = search.replace(
            regexDisciplineFootballFutsal,
            '$1' + discipline
        );
    } else if (search.match(regexDiscipline)) {
        const idx = search.indexOf('discipline=');
        if (search[idx - 1] === '?' || search[idx - 1] === '&') {
            search = search.replace(
                regexDiscipline,
                `${search[idx - 1]}discipline=${discipline}`
            );
        }
    } else {
        const and = getUrlAnd(search);
        search += `${and}discipline=${discipline}`;
    }

    return search;
};
