import { useContext } from 'react';
import { useTranslation as useTranslations } from 'next-i18next';
import { TFunction } from 'i18next';
import { GlobalContext } from 'pages/_app';
import { TNamespaceEnum } from 'types/types';

export const plurals = (t: TFunction, key: string, number: number): string => {
    return number === 0
        ? t(key + '-3', { number })
        : number === 1
        ? t(key + '-1', { number })
        : number >= 5
        ? t(key + '-3', { number })
        : t(key + '-2', { number });
};

export const getTranslationKey = (ns: TNamespaceEnum, key: string): string =>
    `${ns}:${key}`;

export const getTranslation = (
    t: TFunction,
    ns: TNamespaceEnum,
    key: string
): string => t(getTranslationKey(ns, key));

export const useTranslation = () => {
    const { nameSpaces } = useContext(GlobalContext);
    return useTranslations(nameSpaces);
};
