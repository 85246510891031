/* eslint-disable no-undef */

const namespaces = [
    'common',
    'lists/countries',
    'lists/languages',
    'modules/person',
    'modules/login',
    'modules/signup',
    'modules/personRecovery',
    'modules/applicationPayment',
    'modules/anonymousPayment',
    'modules/document',
    'modules/renewMembership',
    'pages/landing',
    'pages/oauth',
    'pages/navigation',
];

module.exports = {
    lng: 'cs',
    debug: process.env.NODE_ENV === 'development',
    defaultLanguage: 'cs',
    i18n: {
        defaultLocale: 'cs',
        locales: ['cs', 'en', 'de', 'fr', 'es'],
        localeDetection: false,
    },
    serializeConfig: false,
    reloadOnPrerender: true,
    ns: namespaces,
    initImmediate: true,
    fallbackLng: 'cs',
    load: 'languageOnly',
    preload: ['cs'],
    react: {
        useSuspense: false,
        // wait: true,
    },
    partialBundledLanguages: true,
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
};
