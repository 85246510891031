import React, { ReactElement, useEffect, useState } from 'react';
import styles from './TopProgressBar.module.scss';

const TopProgressBar = (): ReactElement => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        window.pageRefresh = (event: MouseEvent) => {
            if (
                event &&
                (event.ctrlKey ||
                    event.shiftKey ||
                    event.metaKey || // apple
                    (event.button && event.button == 1)) // middle mouse button click
            )
                return;
            setLoading(true);
        };
        window.pageRedirect = (url: string, newWindow?: boolean) => {
            console.log('redirecting to: ' + url);
            if (newWindow) {
                // eslint-disable-next-line security/detect-non-literal-fs-filename
                window.open(url);
            } else {
                setLoading(true);
                window.location.href = url;
            }
        };
    }, []);

    if (!loading) return null;

    return (
        <div className={styles.loadingBar}>
            <div />
        </div>
    );
};

export default TopProgressBar;
