import React, { CSSProperties, ReactElement } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { usePopperTooltip } from 'react-popper-tooltip';
import ReactMarkdown from 'react-markdown';
import styles from './Tooltip.module.scss';

export type DirectionType =
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end';

interface Props {
    children: React.ReactChild;
    label: string | ReactElement;
    type?: 'default' | 'danger';
    direction?: DirectionType;
    align?: 'left' | 'center';
    interactive?: boolean;
    containerClass?: string;
    offset?: [number, number];
    onVisibleChange?: (state: boolean) => void;
    containerStyles?: CSSProperties;
    delayShow?: number;
    delayHide?: number;
    pointerCursor?: boolean;
    hideTooltip?: boolean;
    center?: boolean;
    noWidth?: boolean;
}

const Tooltip = ({
    children,
    label,
    type,
    direction = 'top',
    align = 'center',
    interactive = false,
    offset,
    onVisibleChange,
    containerStyles,
    containerClass,
    delayShow,
    delayHide,
    pointerCursor,
    hideTooltip,
    center,
    noWidth,
}: Props): ReactElement => {
    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
    } = usePopperTooltip({
        delayShow: delayShow !== undefined ? delayShow : 250,
        delayHide: delayHide !== undefined ? delayHide : 150,
        followCursor: false,
        placement: direction,
        interactive,
        offset,
        onVisibleChange,
    });

    return (
        <div
            className={classNames(styles.tooltip, containerClass, {
                [styles.cursor_help]: !pointerCursor && !!label,
                [styles.cursor_pointer]: pointerCursor,
                [styles.noWidth]: noWidth,
            })}
        >
            <div
                className={classNames(styles.children, {
                    [styles.center]: center,
                })}
                ref={setTriggerRef}
            >
                {children}
            </div>
            {label &&
                !hideTooltip &&
                visible &&
                ReactDOM.createPortal(
                    <div
                        ref={setTooltipRef}
                        {...getTooltipProps({
                            className: classNames(styles['tooltip-container'], {
                                [styles.danger]: type === 'danger',
                                [styles.center]: align === 'center',
                            }),
                            style: containerStyles,
                        })}
                    >
                        <div
                            {...getArrowProps({
                                className: styles['tooltip-arrow'],
                            })}
                        />
                        {(typeof label === 'string' && (
                            <ReactMarkdown>{label}</ReactMarkdown>
                        )) || <div>{label}</div>}
                    </div>,
                    document.getElementById('tooltip-popper')
                )}
        </div>
    );
};

export default Tooltip;
