import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { Title } from 'components/typography';
import { isBrowser } from 'utils';
import styles from './Loading.module.scss';

interface Props {
    loadingText?: string;
    wholeScreen?: boolean;
    noMarginBottom?: boolean;
}

const Loading = ({
    loadingText,
    wholeScreen,
    noMarginBottom,
}: Props): ReactElement => {
    const loadingElement = (
        <div
            className={classNames(styles.root, {
                [styles.maxHeight]: wholeScreen,
            })}
        >
            <div
                className={classNames({ [styles.loadingWrapper]: wholeScreen })}
            >
                <div
                    className={classNames(styles.icons, {
                        [styles.noMarginBottom]: noMarginBottom,
                    })}
                >
                    <span
                        className={classNames(styles.icon, styles.iconFirst)}
                    />
                    <span
                        className={classNames(styles.icon, styles.iconSecond)}
                    />
                    <span
                        className={classNames(styles.icon, styles.iconThird)}
                    />
                </div>
                {!!loadingText && (
                    <Title className={styles.title}>{loadingText}</Title>
                )}
            </div>
        </div>
    );

    if (wholeScreen) {
        return (
            isBrowser() &&
            ReactDOM.createPortal(
                <div className={styles.wholeScreen}>{loadingElement}</div>,
                document.getElementById('loading')
            )
        );
    }

    return loadingElement;
};

export default Loading;
