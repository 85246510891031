import jwtDecode from 'jwt-decode';
import { getTokenCookie } from 'utils/helpers/browser/cookies';
import { getDiscipline } from 'utils/helpers/browser/discipline';

export enum TokenPurpose {
    Access = 'access',
    Refresh = 'refresh',
}

export const ACCESS_TOKEN_NAME = 'access_token';
export const REFRESH_TOKEN_NAME = 'refresh_token';

interface JwtToken {
    exp: number;
    unique_name: string;
}

/**
 * Returns a token name (=key) based on its purpose
 */
export function getTokenName(purpose: TokenPurpose | string): string {
    switch (purpose) {
        case TokenPurpose.Access:
            return ACCESS_TOKEN_NAME;
        case TokenPurpose.Refresh:
            return REFRESH_TOKEN_NAME;
        default:
            throw new Error('Unknown token purpose ' + purpose);
    }
}

/**
 * Returns date when the JWT token expires.
 */
export function getJwtExpiration(token: string): Date | null {
    const decodedToken = jwtDecode(token) as JwtToken;

    // No expiration date
    if (!decodedToken.exp) {
        return null;
    }

    // Convert to Date and return
    return new Date(decodedToken.exp * 1000);
}

/**
 * Tests if the JWT token is already expired
 */
export function isJwtExpired(token: string): boolean {
    // Get expiration date
    const jwtExpiration = getJwtExpiration(token);
    if (!jwtExpiration) return true;

    // Get current date
    const now = new Date();

    // Compare
    return now > jwtExpiration;
}

/**
 * Returns username (email) of the jwt owner/user.
 */
export function getJwtUserName(token: string): string | null {
    const decodedToken = jwtDecode(token) as JwtToken;

    // No username
    if (!decodedToken.unique_name) {
        return null;
    }

    // Return the username
    return decodedToken.unique_name;
}

export function jwtContainsPath(
    token: string | null | undefined,
    path: string
): boolean {
    if (!token) {
        return false;
    }

    try {
        const decodedToken = jwtDecode<JwtToken>(token);
        // Check if the path exists in the decoded token
        return hasNestedProperty(decodedToken, path);
    } catch (error) {
        // Handle the error (e.g., invalid token)
        console.error('Invalid token:', error);
        return false;
    }
}

export function userHasRole(roleName: string): boolean {
    const token = getTokenCookie(TokenPurpose.Access);
    const hasRole = jwtContainsPath(
        token,
        `role.${getDiscipline()}.${roleName}`
    );

    if (!hasRole) {
        console.error(`User not signed in or lacks role ${roleName}.`);
    }

    return hasRole;
}

function hasNestedProperty(obj: any, propertyPath: string): boolean {
    const properties = propertyPath.split('.');

    for (const property of properties) {
        if (obj && typeof obj === 'object' && property in obj) {
            obj = obj[property];
        } else {
            return false;
        }
    }

    return true;
}
