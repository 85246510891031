import React, { MouseEvent, ReactElement, useState } from 'react';
import classNames from 'classnames';
import A from 'components/layouts/A';
import ButtonLoader from 'components/loading/ButtonLoader';
import { debounce } from 'utils/helpers/helpers';
import styles from './Button.module.scss';

export interface ButtonProps {
    children?: React.ReactChild;
    isSubmitType?: boolean;
    isSecondary?: boolean;
    isTransparent?: boolean;
    anyWidth?: boolean;
    isWhite?: boolean;
    isDisabled?: boolean;
    isLoading?: boolean;
    className?: string;
    disabledReason?: string;
    href?: string;
    id?: string;
    onClick?: (e: MouseEvent<Element>) => void;
    resolveImmediately?: boolean;
    useDownload?: boolean;
    forwardRef?: React.MutableRefObject<any>;
}

const onClickAction = (
    onClick: () => void,
    setLoading: (loading: boolean) => void,
    resolveImmediately: boolean
) => {
    if (resolveImmediately) {
        if (onClick) onClick();
    } else {
        setLoading(true);
        if (onClick) {
            const debounced = debounce(250, () => {
                const result = new Promise((resolve) => {
                    onClick();
                    resolve(true);
                });
                if (result) setLoading(false);
            });
            debounced();
        }
    }
};

const Button = ({
    children,
    isSecondary,
    isTransparent,
    className,
    onClick,
    isSubmitType,
    disabledReason,
    isWhite,
    anyWidth,
    isDisabled,
    isLoading: loading,
    href,
    id,
    resolveImmediately,
    useDownload,
    forwardRef,
}: ButtonProps): ReactElement => {
    const [isLoading, setLoading] = useState<boolean>(loading);

    const styleType = isSecondary
        ? styles.secondary
        : isTransparent
        ? styles.transparent
        : isWhite
        ? styles.white
        : styles.primary;

    const innerLoading = isLoading || loading;

    if (href) {
        return (
            <A
                forwardRef={forwardRef}
                className={classNames(
                    styles.root,
                    styleType,
                    anyWidth ? styles.anyWidth : null,
                    className,
                    { [styles.disabled]: isDisabled || innerLoading }
                )}
                href={href}
                onClick={
                    onClick
                        ? () =>
                              onClickAction(
                                  undefined,
                                  setLoading,
                                  resolveImmediately
                              )
                        : undefined
                }
                id={id}
                useDownload={useDownload}
            >
                {children}
            </A>
        );
    }

    return (
        <button
            className={classNames(
                styles.root,
                styleType,
                anyWidth ? styles.anyWidth : null,
                className,
                { [styles.disabled]: isDisabled || innerLoading }
            )}
            onClick={
                onClick
                    ? (e) =>
                          onClickAction(
                              () => onClick(e),
                              setLoading,
                              resolveImmediately
                          )
                    : undefined
            }
            type={isSubmitType ? 'submit' : 'button'}
            disabled={isDisabled || isLoading}
            id={id}
            ref={forwardRef}
        >
            {!!disabledReason && (
                <span className={styles.disabledReason}>{disabledReason}</span>
            )}
            <span>
                <span className={classNames({ [styles.hidden]: innerLoading })}>
                    {children}
                </span>
                <span className={styles.loader}>
                    {innerLoading && <ButtonLoader />}
                </span>
            </span>
        </button>
    );
};

export default Button;
