import { NextApiRequest, NextApiResponse } from 'next';
import { NextApiRequestQuery } from 'next/dist/server/api-utils';

export type BadgeBackground = 'info' | 'warning';

export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<
    T,
    Exclude<keyof T, Keys>
> &
    {
        [K in Keys]-?: Required<Pick<T, K>> &
            Partial<Pick<T, Exclude<Keys, K>>>;
    }[Keys];

export type Player = {
    id?: string | number;
    internalId?: string;
    name: string;
    surname?: string;
    proximityScore?: number;
    fifaId?: string | null;
    birthDate: Date | string;
    birthCountry: string;
    association?: string;
    nationalIdentifier?: string;
    registeredAt?: Date | string;
    duplicates?: number;
    registrationType?: RegistrationType;
    history?: PlayerHistory[];
    imageUrl?: string;
    club?: string;
    registrations?: PlayerRegistrations;
};

export type Club = {
    internalId?: string;
    id: string | number;
    name: string;
    duplicates?: number;
    address?: Address;
    updatedAt?: Date | string;
    registeredAt?: Date | string;
    imageUrl?: string;
    fifaId?: string;
    url?: string;
    discipline?: string;
};

export type Member = {
    id: string;
    internalId?: string;
    name?: string;
    surname?: string;
    club?: string;
    nationalIdentifier?: string;
    member?: boolean;
    roles?: RoleEnum[];
    gender: Gender;
};

export type Address = {
    city: string;
    street: string;
    postalCode: string;
    country: string;
};

export type PlayerHistory = {
    discipline: DisciplineEnum;
    association: string;
    registrationType: RegistrationType;
    activeSpan: string;
};

export type PlayerRegistrations = {
    football: DisciplineRegistration;
    futsal: DisciplineRegistration;
};

export type DisciplineRegistration = {
    association?: string;
    active: boolean;
    level: RegistrationType;
};

export type ServerSideInitProps = {
    req: NextApiRequest;
    res: NextApiResponse;
    params: { [key: string]: any };
    query?: NextApiRequestQuery;
    locale?: string;
};

export type Error = {
    status: number;
    code: string;
};

export type OptionType = {
    name: string;
    value: string;
};

export type RegistrationType = 'amateur' | 'pro';

export enum DisciplineEnum {
    football = 'football',
    futsal = 'futsal',
}

export enum LangEnum {
    cs = 'cs',
    en = 'en',
    de = 'de',
    fr = 'fr',
    es = 'es',
}

export type DialogStatus = 'default' | 'loading' | 'success' | 'error';

export type ConfirmDialogType = 'new' | 'pair' | 'noDuplicate';

export type RetryDialogType = 'new' | 'pair' | 'duplicates';

export enum RoleEnum {
    player = 'player',
    coach = 'coach',
    referee = 'referee',
    member = 'member',
    delegate = 'delegate',
}

export type Gender = 'male' | 'female';

export enum GoalTypeEnum {
    GOAL = 'goal',
    OWN_GOAL = 'own_goal',
    PENALTY_KICKS = 'penalty_kicks',
    PENALTY_SHOOTOUT = 'penalty_shootout',
}

export enum PunishmentTypeEnum {
    YELLOW = 'yellow',
    RED = 'red',
}

export enum AddressTypeEnum {
    shipping = 'shipping',
    section = 'section',
    workplace = 'workplace',
    temp_residence = 'temp_residence',
    foreign_section = 'foreign_section',
    foreign_residence = 'foreign_residence',
}

export enum TNamespaceEnum {
    common = 'common',
    countries = 'lists/countries',
    languages = 'lists/languages',
    person = 'modules/person',
    login = 'modules/login',
    signup = 'modules/signup',
    personRecovery = 'modules/personRecovery',
    applicationPayment = 'modules/applicationPayment',
    anonymousPayment = 'modules/anonymousPayment',
    document = 'modules/document',
    renewMembership = 'modules/renewMembership',
    landingPage = 'pages/landing',
    oauthPage = 'pages/oauth',
    navigation = 'pages/navigation',
}
