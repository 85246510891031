// main
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { NextApiRequestQuery } from 'next/dist/server/api-utils';
import { Configuration } from './configuration';
import AuthService from './services/auth.service';
import OAuthService from './services/oauth.service';
import UserService from './services/user.service';
import PlayerService from './services/player.service';
import ClubService from './services/club.service';
import FifaService from './services/fifa.service';
import MemberService from './services/member.service';
import ListService from './services/list.service';
import PersonService from './services/person.service';
import NoticeBoardService from './services/noticeBoard.service';
import LegalPersonService from './services/legalPerson.service';
import RefereeService from './services/referee.service';
import CoachService from './services/coach.service';
import OrganisationUnitService from './services/organisationUnit.service';
import ApplicationService from './services/application.service';
import CompetitionRewardsService from './services/competitionRewards.service';
import AccountService from './services/account.service';
import CompetitionService from './services/competition.service';
import MatchService from './services/match.service';
import PaymentService from './services/payment.service';
import DelegateService from './services/delegate.service';
import ConfigService from './services/config.service';
import MessageService from './services/message.service';
import InternalUsersService from './services/internalUsers.service';
import MembershipService from './services/membership.service';
import PitchService from './services/pitch.service';
import ClubUnionService from './services/clubUnion.service';
import SubsidyService from './services/subsidy.service';
import NominationService from './services/nomination.service';
import ProceedingService from './services/proceeding.service';
import GroupService from './services/group.service';
import AgentService from './services/agent.service';
import AssociationService from './services/association.service';
import LicenseService from './services/license.service';
import AuditLogService from './services/auditLog.service';
import TestService from './services/test.service';
import logger from 'utils/helpers/server/logger';
import PersonRecoveryService from 'submodules/api_middleware/src/services/personRecovery.service';
import MarketingAgreementService from 'submodules/api_middleware/src/services/marketingAgreement.service';
import TargitoService from 'submodules/api_middleware/src/services/targito.service';
import IntegrationService from 'submodules/api_middleware/src/services/integration.service';

export const decodeUriComponentWithPlus = (value: string): string =>
    value ? decodeURIComponent(value.replace(/\+/g, ' ')) : value;

export const FILTER_PREFIX = 'fi_';
export const SORT_KEY = 'sort[]';

export const getLocationSearchQuery = (): NextApiRequestQuery =>
    window.location.search
        .replace(/^\?/, '')
        .split('&')
        .reduce((result: NextApiRequestQuery, searchString) => {
            const [key, value] = searchString.split('=');
            const decodedKey = decodeURIComponent(key);

            if (new RegExp(/\[]$/).test(decodedKey)) {
                result[decodedKey] = [
                    ...((result[decodedKey] as string[])?.map((val) =>
                        decodeUriComponentWithPlus(val)
                    ) || []),
                    decodeUriComponentWithPlus(value),
                ];
            } else {
                result[decodedKey] = decodeUriComponentWithPlus(value);
            }
            return result;
        }, {});

export const checkUnused = (
    obj: { [key: string]: any },
    key: string
): boolean =>
    ((!!obj[key] || obj[key] === 0) &&
        (typeof obj[key] !== 'string' || obj[key].trim() !== '')) ||
    (Array.isArray(obj[key]) && obj[key].length > 0);

export const removePrefixQueryKey = (key: string): string =>
    key?.startsWith(FILTER_PREFIX) ? key.slice(FILTER_PREFIX.length) : key;

export const removePrefixFromQuery = (query: {
    [key: string]: any;
}): { [key: string]: any } => {
    return Object.keys(query).reduce((result: NextApiRequestQuery, key) => {
        result[removePrefixQueryKey(key)] = query[key];
        return result;
    }, {});
};

const mapMultipleArrayToQuery = (
    obj: string | string[],
    key: string
): string => {
    let queryKey = removePrefixQueryKey(key);
    if (key.endsWith('[][]')) queryKey = queryKey.slice(0, -2);
    if (Array.isArray(obj)) {
        return queryKey + '=' + obj.join('&' + queryKey + '=');
    } else {
        return queryKey + '=' + encodeURIComponent(obj);
    }
};

const mapObjectToQuery = (obj: { [key: string]: any }, key: string): string => {
    const queryKey = removePrefixQueryKey(key);
    if (key.endsWith('[][]') || key.endsWith('[]')) {
        return mapMultipleArrayToQuery(obj[key], key);
    } else if (!key.endsWith('[]') && Array.isArray(obj[key])) {
        return mapMultipleArrayToQuery(obj[key], key + '[]');
    }

    return queryKey + '=' + encodeURIComponent(obj[key]);
};

const isInitFilter = (obj: { [key: string]: any }, key: string): boolean => {
    if (!key.startsWith(FILTER_PREFIX)) {
        if (obj[FILTER_PREFIX + key]) {
            return true;
        }

        return false;
    }
    return false;
};

export const objectToQuery = (obj: { [key: string]: any }): string =>
    Object.keys(obj)
        .filter((key) => checkUnused(obj, key))
        .filter((key) => !isInitFilter(obj, key))
        .map((key) => mapObjectToQuery(obj, key))
        .join('&');

export const deleteUnusedQuery = (
    obj: {
        [key: string]: any;
    },
    availableFilters: string[]
): { [key: string]: any } => {
    const query = getLocationSearchQuery();
    const queryWithoutFilters = Object.keys(query)
        .filter((key) => !availableFilters.includes(key))
        .reduce((result: NextApiRequestQuery, key) => {
            result[key] = query[key];
            return result;
        }, {});

    return Object.keys(obj).reduce((result: Record<string, any>, key) => {
        result[key] = obj[key];
        return result;
    }, queryWithoutFilters);
};

export const getPureQuery = (
    query: NextApiRequestQuery
): NextApiRequestQuery => {
    const normalKeys: string[] = [];
    const fiKeys: string[] = [];

    Object.keys(query).forEach((key) => {
        if (key.startsWith(FILTER_PREFIX)) {
            fiKeys.push(removePrefixQueryKey(key));
        } else {
            normalKeys.push(key);
        }
    });

    let newQuery = normalKeys.reduce((obj: NextApiRequestQuery, key) => {
        obj[key] = query[key];
        return obj;
    }, {});

    newQuery = fiKeys.reduce((newQuery: NextApiRequestQuery, key) => {
        newQuery[key] = query[FILTER_PREFIX + key];
        return newQuery;
    }, newQuery);

    return newQuery;
};

export interface IAuthErrorHandler {
    /**
     * Takes an auth error response and returns a new/same response depending on the situation.
     */
    handle(
        caller: Client,
        client: AxiosInstance,
        response: AxiosResponse<any>,
        error: any
    ): Promise<any>;
}

export default class Client {
    private axios: AxiosInstance;
    private configuration: Configuration;
    private userToken: string;

    constructor(
        configuration: Configuration,
        ipAddress?: string,
        userToken?: string
    ) {
        // Config
        this.mergeConfig(configuration);

        // Axios client
        this.setupAxiosClient();

        // Setup token
        this.setupToken(userToken);

        // Sets up forwarding of client IP address through the middleware to BE
        // in HTTP header 'X-Real-IP'
        this.setupClientIpAddressForwarding(ipAddress);

        // Logger
        this.setupLogger();
    }

    private mergeConfig(configuration: Configuration): void {
        const defaults: Configuration = {
            apiUrl: '', // @TODO Add default API URL
            timeout: 30000,
        };

        this.configuration = {
            ...defaults,
            ...configuration,
        };
    }

    private setupLogger() {
        const logger = this.configuration.logger;
        if (logger) {
            this.axios.interceptors.request.use((config) => {
                logger.info(
                    {
                        url: (config.baseURL || '') + (config.url || ''),
                        authorization: config.headers?.Authorization,
                        method: config.method,
                        params: config.params,
                    },
                    'request logging'
                );

                return config;
            });
        }
    }

    private setupAxiosClient() {
        const base = {
            timeout: this.configuration.timeout,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        };
        this.axios = axios.create({
            ...base,
            paramsSerializer: (params) => objectToQuery(params),
            baseURL: this.configuration.apiUrl,
        });
    }

    private setupToken(userToken?: string) {
        // Set the appropriate user token
        if (userToken) {
            this.setUserToken(userToken);
        }

        // Setup auth interceptor
        this.axios.interceptors.request.use((request) => {
            if (this.userToken) {
                logger.info(`Injecting auth header with a token`);
                request.headers['Authorization'] = `Bearer ${this.userToken}`;
            } else {
                logger.info(`Anonymous client request`);
            }

            return request;
        });
    }

    private setupClientIpAddressForwarding(ipAddress?: string) {
        // Setup auth interceptor
        this.axios.interceptors.request.use((request) => {
            if (ipAddress) {
                request.headers['X-Real-IP'] = ipAddress;
            }
            return request;
        });
    }

    setAuthErrorHandler(authErrorHandler: IAuthErrorHandler) {
        this.axios.interceptors.response.use(
            (res) => {
                return res;
            },
            (error) => {
                const response: AxiosResponse<any> = error.response;

                // The status for auth error must be HTTP 401 Unauthorized
                if (response?.status != 401) {
                    logger.info(
                        'The error is not a HTTP 401 -> skipping 401 auth handler'
                    );
                    return Promise.reject(error);
                }

                // Run the auth handler
                logger.info('Invoking 401 Auth error handler...');
                return authErrorHandler.handle(
                    this,
                    this.axios,
                    response,
                    error
                );
            }
        );
    }

    setUserToken(userToken: string): Client {
        this.userToken = userToken;
        return this;
    }

    association(): AssociationService {
        return new AssociationService(this.axios);
    }

    accounts(): AccountService {
        return new AccountService(this.axios);
    }

    auth(): AuthService {
        return new AuthService(this.axios);
    }

    oAuth(): OAuthService {
        return new OAuthService(this.axios);
    }

    users(): UserService {
        return new UserService(this.axios);
    }

    players(): PlayerService {
        return new PlayerService(this.axios);
    }

    clubs(): ClubService {
        return new ClubService(this.axios);
    }

    clubUnions(): ClubUnionService {
        return new ClubUnionService(this.axios);
    }

    fifa(): FifaService {
        return new FifaService(this.axios);
    }

    members(): MemberService {
        return new MemberService(this.axios);
    }

    payments(): PaymentService {
        return new PaymentService(this.axios);
    }

    lists(): ListService {
        return new ListService(this.axios);
    }

    persons(): PersonService {
        return new PersonService(this.axios);
    }

    legalPersons(): LegalPersonService {
        return new LegalPersonService(this.axios);
    }

    integration(): IntegrationService {
        return new IntegrationService(this.axios);
    }

    targito(): TargitoService {
        return new TargitoService(this.axios);
    }

    referee(): RefereeService {
        return new RefereeService(this.axios);
    }

    coach(): CoachService {
        return new CoachService(this.axios);
    }

    organisationUnits(): OrganisationUnitService {
        return new OrganisationUnitService(this.axios);
    }

    competitions(): CompetitionService {
        return new CompetitionService(this.axios);
    }

    pitches(): PitchService {
        return new PitchService(this.axios);
    }

    proceedings(): ProceedingService {
        return new ProceedingService(this.axios);
    }

    noticeBoard(): NoticeBoardService {
        return new NoticeBoardService(this.axios);
    }

    applications(): ApplicationService {
        return new ApplicationService(this.axios);
    }

    internalUsers(): InternalUsersService {
        return new InternalUsersService(this.axios);
    }

    subsidies(): SubsidyService {
        return new SubsidyService(this.axios);
    }

    competitionRewards(): CompetitionRewardsService {
        return new CompetitionRewardsService(this.axios);
    }

    matches(): MatchService {
        return new MatchService(this.axios);
    }

    delegates(): DelegateService {
        return new DelegateService(this.axios);
    }

    config(): ConfigService {
        return new ConfigService(this.axios);
    }

    messages(): MessageService {
        return new MessageService(this.axios);
    }

    memberships(): MembershipService {
        return new MembershipService(this.axios);
    }

    nominations(): NominationService {
        return new NominationService(this.axios);
    }

    groups(): GroupService {
        return new GroupService(this.axios);
    }

    agents(): AgentService {
        return new AgentService(this.axios);
    }

    licenses(): LicenseService {
        return new LicenseService(this.axios);
    }

    auditLog(): AuditLogService {
        return new AuditLogService(this.axios);
    }

    personRecovery(): PersonRecoveryService {
        return new PersonRecoveryService(this.axios);
    }

    marketingAgreement(): MarketingAgreementService {
        return new MarketingAgreementService(this.axios);
    }

    test(): TestService {
        return new TestService(this.axios);
    }
}
