import React, { ReactElement } from 'react';
import classNames from 'classnames';
import styles from './H3.module.scss';

interface Props {
    children?: React.ReactChild;
    className?: string;
}

const H3 = (props: Props): ReactElement => {
    const { children, className } = props;

    return <h3 className={classNames(styles.root, className)}>{children}</h3>;
};

export default H3;
