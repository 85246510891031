import pino from 'pino';
import { NextApiRequest, NextApiResponse } from 'next';
import { getEnvVal } from '../helpers';
const { NEXT_PUBLIC_VERSION } = getEnvVal();

// TODO use app version not some number
const logger = pino({
    prettyPrint: process.env.NODE_ENV === 'development',
    name: 'ISFrontend', // TODO Move to own logger (API client)
    timestamp: () => `,"time":"${new Date(Date.now()).toISOString()}"`,
    base: {
        pid: NEXT_PUBLIC_VERSION,
    },
    level: process.env.NODE_ENV === 'development' ? 'debug' : 'info',
});

export default logger;

// TODO create logger with name
export const loggerMiddleware =
    (handler: (req: NextApiRequest, res: NextApiResponse) => Promise<void>) =>
    (req: NextApiRequest, res: NextApiResponse) => {
        logger.info(
            {
                url: req.headers.host + req.url, // TODO missing protocol
                Method: req.method,
            },
            'request logging'
        );

        return handler(req, res);
    };
