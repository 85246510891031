import React, { MouseEvent, ReactElement, useRef } from 'react';
import classNames from 'classnames';
import { debounce } from 'utils/helpers/helpers';
import Icon from 'components/icon/Icon';
import styles from './IconButton.module.scss';

export interface ButtonProps {
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    onClick?: (e: MouseEvent<Element>) => void;
    isSubmitType?: boolean;
    isDisabled?: boolean;
    className?: string;
}

const onClickAction = (btnRef: React.MutableRefObject<any>) => {
    const debounced = debounce(250, () => {
        if (btnRef?.current) {
            btnRef.current.click();
        }
    });
    debounced();
};

const IconButton = ({
    icon,
    onClick,
    isSubmitType,
    isDisabled,
    className,
}: ButtonProps): ReactElement => {
    const btnRef = useRef(null);

    return (
        <div className={classNames(styles.wrapper, className)}>
            <button
                ref={btnRef}
                disabled={isDisabled}
                className={classNames(styles.offScreen, {
                    [styles.disabled]: isDisabled,
                })}
                type={isSubmitType ? 'submit' : 'button'}
                onClick={onClick}
            />
            <div className={styles.iconWrapper}>
                <Icon
                    icon={icon}
                    noStrip
                    className={classNames({
                        [styles.disabled]: isDisabled,
                    })}
                    onClick={() => onClickAction(btnRef)}
                />
            </div>
        </div>
    );
};

export default IconButton;
