import React, {
    ChangeEvent,
    KeyboardEvent,
    ReactElement,
    Ref,
    CSSProperties,
    useRef,
} from 'react';
import classNames from 'classnames';
import IconButton from './IconButton';
import Icon from 'components/icon/Icon';
import SearchIcon from 'components/icon/icons/search.svg';
import styles from './Input.module.scss';

export enum InputType {
    'text' = 'text',
    'password' = 'password',
    'email' = 'email',
    'date' = 'date',
}

interface Props {
    name?: string;
    value?: string;
    placeholder?: string;
    className?: string;
    bigger?: boolean;
    icon?: React.FC<React.SVGProps<SVGSVGElement>>;
    iconClass?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
    onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    type?: InputType;
    autocomplete?: string;
    whiteBackground?: boolean;
    withBorder?: boolean;
    withBottomBorder?: boolean;
    defaultValue?: string;
    onClick?: () => void;
    highlighted?: boolean;
    forwardRef?: Ref<any>;
    minLength?: number;
    maxLength?: number;
    pattern?: string;
    errorMessage?: string;
    style?: CSSProperties;
    inputStyle?: CSSProperties;
    attributes?: { [key: string]: string };
    disabled?: boolean;
    hideArrows?: boolean;
    tabIndex?: number;
    withSearchButton?: boolean;
    autoFocus?: boolean;
    readOnly?: boolean;
}

const Input = ({
    name,
    placeholder,
    className,
    bigger,
    icon,
    type = InputType.text,
    onChange,
    onKeyDown,
    onFocus,
    autocomplete,
    value,
    disabled,
    whiteBackground,
    withBorder,
    onClick,
    onBlur,
    highlighted,
    forwardRef,
    iconClass,
    minLength,
    maxLength,
    pattern,
    style,
    inputStyle,
    errorMessage,
    withBottomBorder,
    onKeyPress,
    attributes = {},
    tabIndex,
    withSearchButton,
    autoFocus,
    readOnly,
}: Props): ReactElement => {
    const inputRef = useRef(null);

    return (
        <>
            <div
                className={classNames(styles.root, className, {
                    [styles.withBottomBorder]: !!withBottomBorder,
                })}
                onClick={onClick}
                style={style}
            >
                <input
                    name={name}
                    tabIndex={tabIndex}
                    className={classNames(styles.input, {
                        [styles.bigger]: bigger,
                        [styles.whiteBackground]: whiteBackground,
                        [styles.withBorder]: !!withBorder,
                        [styles.highlighted]: highlighted,
                    })}
                    onKeyPress={onKeyPress}
                    pattern={pattern}
                    minLength={minLength}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    type={type}
                    onChange={onChange}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    onFocus={onFocus}
                    autoComplete={autocomplete}
                    value={value}
                    disabled={disabled}
                    ref={forwardRef || inputRef}
                    style={inputStyle}
                    readOnly={readOnly}
                    autoFocus={autoFocus}
                    {...attributes}
                />
                {icon && (
                    <div className={classNames(iconClass, styles.icon)}>
                        <Icon icon={icon} />
                    </div>
                )}
                {withSearchButton && (
                    <IconButton
                        className={styles.searchIcon}
                        icon={SearchIcon}
                        isSubmitType
                    />
                )}
            </div>
            {!!errorMessage && (
                <div className={styles.error}>{errorMessage}</div>
            )}
        </>
    );
};

export default Input;
