import { getUrlParamMessage, ToastInterface } from 'components/layouts/Toast';
import { StateInterface } from 'components/interfaces/GeneralInterface';
import { getDiscipline } from 'utils/helpers/browser/discipline';
import { DisciplineEnum } from 'submodules/api_middleware';

export const Routes = [
    '/',
    '/home',
    '/maintenance',
    '/password-reset',
    '/oauth',
    '/signup',
    '/signup-by-club-admin',
    '/person-recovery',
    '/person-recovery/confirm',
    '/person-recovery/email',
    '/renew-membership',
    '/anonymous-payment',
    '/anonymous-payment/{0}/waiting',
    '/anonymous-payment/{0}/success',
    '/anonymous-payment/{0}/failed',
    '/application-payment',
    '/application-payment/{0}/waiting',
    '/application-payment/{0}/success',
    '/application-payment/{0}/failed',
    '/my',
    '/my/messages',
    '/my/messages/{0}',
    '/my/proceedings',
    '/my/subsidies',
    '/user/password',
    '/logout',
    '/applications',
    '/applications/{0}',
    '/competitionrewards',
    '/competitionrewards/new',
    '/competitionrewards/{0}',
    '/users',
    '/users/create/new',
    '/decisions',
    '/decisions/{0}',
    '/decisions/create',
    '/delegations',
    '/regulations',
    '/regulations/{0}',
    '/regulations/new',
    '/compare/players',
    '/compare/players/{0}',
    '/compare/clubs',
    '/compare/clubs/{0}',
    '/members',
    '/members/{0}',
    '/members/{0}/overview',
    '/members/{0}/details',
    '/members/{0}/billing',
    '/members/{0}/requests',
    '/members/{0}/accounts/transactions',
    '/members/{0}/accounts/rewards',
    '/members/{0}/accounts/rewards/{1}',
    '/members/{0}/accounts/issued',
    '/members/{0}/accounts/issued/{1}',
    '/members/{0}/penalization',
    '/members/{0}/user_access',
    '/members/{0}/applications',
    '/members/{0}/audit-log',
    '/members/{0}/absences',
    '/players',
    '/players/{0}',
    '/players/{0}/overview',
    '/players/{0}/contracts',
    '/players/{0}/contracts/create',
    '/players/{0}/contracts/{1}',
    '/players/{0}/contracts/{1}/edit',
    '/players/{0}/matches',
    '/players_contracts',
    '/referees',
    '/referees/{0}',
    '/referees/{0}/licences',
    '/referees/{0}/matches',
    '/delegates',
    '/delegates/{0}',
    '/delegates/{0}/matches',
    '/delegates/{0}/licences',
    '/coaches',
    '/coaches/{0}',
    '/coaches/{0}/licences',
    '/coaches/{0}/matches',
    '/clubs',
    '/clubs/{0}',
    '/clubs/{0}/requests',
    '/clubs/{0}/applications',
    '/clubs/{0}/accounts/transactions',
    '/clubs/{0}/accounts/subsidies',
    '/clubs/{0}/accounts/issued',
    '/clubs/{0}/accounts/issued/{1}',
    '/clubs/{0}/management',
    '/clubs/{0}/contracts',
    '/clubs/{0}/licence_management',
    '/clubs/{0}/bulk_memberships',
    '/clubs/{0}/bulk_memberships/{1}',
    '/clubs/{0}/bulk_memberships/create',
    '/clubs/{0}/financial_support',
    '/clubs/{0}/players',
    '/clubs/{0}/competitions',
    '/clubs/{0}/competitions/{1}/team/{2}/stats',
    '/clubs/{0}/competitions/{1}/team/{2}/player_autumn',
    '/clubs/{0}/competitions/{1}/team/{2}/player_spring',
    '/clubs/{0}/matches',
    '/clubs/{0}/pitches',
    '/clubs/{0}/persons',
    '/clubs/{0}/transfers',
    '/clubs/{0}/transfers/{1}/declaration/{2}/player/{3}',
    '/clubs/{0}/signatures',
    //UNIONS
    '/club-unions',
    //MATCHES
    '/matches',
    '/matches/{0}',
    '/matches/{0}/home',
    '/matches/{0}/visitor',
    '/matches/{0}/report',
    '/matches/{0}/delegate_report',
    '/matches/{0}/rewards',
    '/matches/{0}/audit-log',
    //LEAGUES
    '/leagues',
    '/leagues/{0}',
    '/leagues/{0}/teams',
    '/leagues/{0}/dates',
    '/leagues/{0}/matches',
    '/leagues/{0}/stats',
    '/leagues/{0}/stats/teams',
    '/leagues/{0}/stats/players',
    '/leagues/{0}/reports_requests',
    '/leagues/create/new',
    //ADMINISTRATION
    '/proceedings-stk',
    '/proceedings-stk/{0}',
    '/proceedings-stk/new',
    '/proceedings-dk',
    '/proceedings-dk/{0}',
    '/proceedings-dk/new',
    //LEGAL PERSONS
    '/legal-persons',
    '/legal-persons/{0}',
    '/legal-persons/create',
    //ORGANISATION UNITS
    '/organisation-units',
    '/organisation-units/{0}',
    '/organisation-units/{0}/applications',
    '/organisation-units/{0}/transactions',
    '/organisation-units/{0}/members',
    '/organisation-units/{0}/accounts/subsidies',
    '/organisation-units/{0}/accounts/grants',
    '/organisation-units/{0}/accounts/grants/{1}',
    '/organisation-units/{0}/{1}',
    '/organisation-units/{0}/{1}/nominations',
    '/organisation-units/{0}/{1}/nominations/{2}',

    // DOCUMENTS
    '/accounts/issued',
    '/accounts/rewards',
    '/accounts/grants',
    '/accounts/subsidies',
    //MEMBERSHIPS
    '/memberships',
    '/bulk-memberships',
    '/accounts/transactions',
    '/accounts/transactions-templates',
    //Subsidies
    '/subsidies',
    '/subsidies/{0}',
    '/subsidies/new',
    '/subsidies-legal-persons',
    //NOMINATIONS
    '/nominations',
    '/nominations/create',

    '/clenstvi-podminky',
    '/group-users',

    '/agents',
    '/agents/{0}',
    '/agents/{0}/contracts',
    '/agents/{0}/contracts/new',
    '/agents/{0}/contracts/{1}',
    '/agents/{0}/insurances',
    '/agents/{0}/insurances/new',
    '/agents/{0}/insurances/{1}',

    '/audit-log',
    '/navigation/documents',
] as const;

export type RoutesType = typeof Routes[number];

type Val = number | string;

export const getRoutePure = (route: RoutesType): string => route.toString();

export const addParamsToRoute = (
    routeE: string,
    params: Val[] = [],
    withConsoleError = false
): string => {
    let route = routeE.toString();
    let lastReplacement = 0;
    if (params.length > 0) {
        params.forEach((value, index) => {
            const stringKey = `/{${index}}`;

            const idx = route.indexOf(stringKey, lastReplacement);

            if (idx !== -1) {
                route =
                    route.substr(0, idx) +
                    '/' +
                    value +
                    route.substr(idx + stringKey.length);

                lastReplacement = idx + ('' + value).length + 1;
            } else {
                if (withConsoleError)
                    console.error(
                        `Such route parameter was not found. Route: ${route}, stringKey: ${stringKey}, value: ${value}, all params: ${JSON.stringify(
                            params
                        )}`
                    );
            }
        });
    }

    return route;
};

export const getRoute = (
    routeE: RoutesType,
    params: Val[] = [],
    toast?: ToastInterface,
    query?: StateInterface,
    addDiscipline = true,
    discipline?: DisciplineEnum
): string => {
    const route = addParamsToRoute(routeE, params, true);
    const urlParams: string[] = [];

    if (toast) {
        urlParams.push(getUrlParamMessage(toast));
    }
    if (query) {
        Object.keys(query).map((key) => {
            if (key === 'discipline' && query[key]) {
                addDiscipline = false;
            }
            urlParams.push(createUrlParam(key, query[key]));
        });
    }
    if (addDiscipline) {
        const disc = discipline || getDiscipline(undefined);
        if (disc) {
            urlParams.push(createUrlParam('discipline', disc));
        }
    }

    let urlParamsResult: string = urlParams.join('&');
    if (urlParamsResult) urlParamsResult = '?' + urlParamsResult;

    return route + urlParamsResult;
};

export const getUrlAnd = (url: string): string =>
    url.includes('?') ? '&' : '?';

export const attachDisciplineToUrl = (url: string): string => {
    if (!url) return url;
    const discipline = getDiscipline(undefined);
    if (discipline) {
        const and = getUrlAnd(url);
        return url + and + createUrlParam('discipline', discipline);
    }
    return url;
};

export const createUrlParam = (key: string, value: string): string =>
    `${key}=${value}`;
