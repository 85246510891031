import React, {
    ReactElement,
    useState,
    useRef,
    useEffect,
    MouseEvent,
    CSSProperties,
} from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import More from 'components/icon/icons/more.svg';
import Icon from 'components/icon/Icon';
import ContextMenuInner from 'components/contextMenu/ContextMenuInner';
import { ButtonProps } from 'components/forms/Button';
import A from 'components/layouts/A';
import { debounce } from 'utils/helpers/helpers';
import styles from './ContextMenu.module.scss';

export type ContextMenuOption = {
    text: string;
    onClick?: () => void;
    url?: string;
    icon?: {
        icon: React.FC<React.SVGProps<SVGSVGElement>>;
        style?: CSSProperties;
    };
    isDisabled?: boolean;
};

interface Props {
    options: ContextMenuOption[];
    isButton?: boolean;
    resolveButtonImmediately?: boolean;
    buttonProps?: ButtonProps;
    children?: React.ReactChild;
    toTop?: boolean;
    className?: string;
    isDisabled?: boolean;
}

const getContextMenuPopperDiv = () => {
    return document.getElementById('contextmenu-popper');
};

const PopperContainer = (children: ReactElement) =>
    ReactDOM.createPortal(children, getContextMenuPopperDiv());

const setPopperContainrPosition = (
    ref: React.MutableRefObject<any>,
    isOpen: boolean
) => {
    const el = getContextMenuPopperDiv();
    if (isOpen) {
        const rect = ref.current.getBoundingClientRect();
        el.style.top = rect.top + window.scrollY + rect.height + 'px';
        el.style.left = rect.left + window.scrollX + rect.width + 'px';
    } else {
        el.style.top = '0';
        el.style.left = '0';
    }
};

const ContextMenu = ({
    options,
    isButton,
    resolveButtonImmediately,
    buttonProps,
    children,
    toTop,
    className,
    isDisabled,
}: Props): ReactElement => {
    const [open, setOpen] = useState(false);
    const menuRef = useRef(null);

    const handleOpen = (e: MouseEvent) => {
        const openState = !open;
        setPopperContainrPosition(menuRef, openState);
        setOpen(openState);
        e.stopPropagation();
    };

    useEffect(() => {
        if (open) {
            // Fix contextMenu position when window resize
            const debouncedHandleResize = debounce(
                0.5,
                function handleResize() {
                    setPopperContainrPosition(menuRef, open);
                }
            );

            window.addEventListener('resize', debouncedHandleResize);
            return () => {
                window.removeEventListener('resize', debouncedHandleResize);
            };
        }
    });

    return (
        <div
            ref={menuRef}
            className={
                isButton
                    ? styles.rootButton
                    : children
                    ? undefined
                    : styles.root
            }
        >
            {children ? (
                <div onClick={handleOpen} className={className}>
                    {children}
                </div>
            ) : (
                <ContextMenuInner
                    handleOpen={handleOpen}
                    isButton={isButton}
                    resolveButtonImmediately={resolveButtonImmediately}
                    buttonProps={buttonProps}
                >
                    <Icon icon={More} className={styles.icon} />
                </ContextMenuInner>
            )}
            {open && !isDisabled && (
                <>
                    {PopperContainer(
                        <>
                            <div
                                className={styles.overlay}
                                onClick={handleOpen}
                            />
                            <div
                                className={classNames(
                                    isButton ? styles.menuButton : styles.menu,
                                    { [styles.toTop]: toTop }
                                )}
                            >
                                {options.map(
                                    (
                                        {
                                            onClick,
                                            text,
                                            url,
                                            icon,
                                            isDisabled,
                                        },
                                        index
                                    ) => {
                                        const onClickWithClose = () => {
                                            setOpen(false);
                                            onClick && onClick();
                                        };
                                        return url && !isDisabled ? (
                                            <div
                                                key={index}
                                                className={styles.option}
                                            >
                                                <A
                                                    href={url}
                                                    onClick={onClickWithClose}
                                                >
                                                    <div
                                                        className={
                                                            styles.optionInner
                                                        }
                                                    >
                                                        {icon && (
                                                            <Icon
                                                                icon={icon.icon}
                                                                style={
                                                                    icon.style
                                                                }
                                                                noStrip
                                                            />
                                                        )}
                                                        {text}
                                                    </div>
                                                </A>
                                            </div>
                                        ) : (
                                            <div
                                                className={
                                                    isDisabled
                                                        ? styles.optionDisabled
                                                        : styles.option
                                                }
                                                onClick={
                                                    isDisabled
                                                        ? null
                                                        : onClickWithClose
                                                }
                                                key={index}
                                            >
                                                <div
                                                    className={
                                                        styles.optionInner
                                                    }
                                                >
                                                    {icon && (
                                                        <Icon
                                                            icon={icon.icon}
                                                            style={icon.style}
                                                            noStrip
                                                        />
                                                    )}
                                                    {text}
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default ContextMenu;
