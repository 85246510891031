import { URLSearchParams } from 'url';
import { NextApiRequestQuery } from 'next/dist/server/api-utils';
import { decodeUriComponentWithPlus } from 'submodules/api_middleware/src/client';

export const getQueryFromUrl = (url: string): NextApiRequestQuery => {
    const urlSplit: string[] = url.split('?');
    const query: NextApiRequestQuery = {};
    if (urlSplit.length > 1) {
        const params: string = urlSplit[1];
        params.split('&').forEach((param) => {
            if (!param) return;
            const paramSplit: string[] = param.split('=');
            query[decodeURIComponent(paramSplit[0])] =
                paramSplit.length > 1
                    ? decodeUriComponentWithPlus(paramSplit[1])
                    : undefined;
        });
    }
    return query;
};

export const getGroupedParamsByKeyFromUrl = (
    params: URLSearchParams
): NextApiRequestQuery =>
    [...params.entries()].reduce((acc, tuple) => {
        const [key, val] = tuple;
        if (key in acc) {
            if (Array.isArray(acc[`${key}`])) {
                acc[`${key}`] = [...acc[`${key}`], val];
            } else {
                acc[`${key}`] = [acc[`${key}`] as string, val];
            }
        } else {
            acc[`${key}`] = val;
        }

        return acc;
    }, {} as NextApiRequestQuery);
